const container = require('./styles/container')
const buttons = require('./styles/buttons')
const input = require('./styles/input')
const pill = require('./styles/pill')

module.exports = {
  mode: 'jit',
  purge: {
    enabled: process.env.NODE_ENV === 'production',
    content: [
      './pages/**/*.{js,ts,jsx,tsx}',
      './components/**/*.{js,ts,jsx,tsx}',
      './stories/**/*.{js,ts,jsx,tsx}',
    ],
    options: {
      safelist: ['overflow-hidden'],
    },
  },
  theme: {
    container: {
      center: true,
    },
    fontFamily: {
      primary: ['Theinhardt Pan', 'sans-serif'],
      secondary: ['Nantes', 'sans-serif'],
      tertiary: ['Source Code Pro', 'sans-serif'],
      nantesTrial: ['Nantes Trial', 'sans-serif'],
    },
    screens: {
      xxs: '280px',
      '2xs': '280px',
      xs: '375px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      slg: '1296px',
      xl: '1536px',
      '2xl': '1921',
      xxl: '1921',
      maxsm: { max: '767px' },
      g_xs: '639px',
      g_sm: '767px',
      g_md: '1023px',
      g_lg: '1297px',
      g_xl: '1535px',
      custom_sm: '767.2px',
      custom_md: '1023.2px',
    },
    extend: {
      colors: {
        black: '#000000',
        newBlack: '#1C1C1C',
        newOrange: '#E36F22',
        newGray: '#C2C3C8',
        borderGray: '#E1E1E1',
        gray: {
          100: '#F7F7F8',
          200: '#E7E8E9',
          300: '#EDEDED',
          400: '#C4C4C4',
          500: '#AAAAAA',
          600: '#9A9A9A',
          700: '#525151',
          800: '#343434',
          900: '#1C1C1C',
        },
        grayback: '#EDEDED',
        primaryA: '#000000',
        primaryB: '#FFFFFF',
        accent: '#E36F22',
        accentHover: '#D1641B',
        accentFocus: '#E16411',
        alert: '#A22935',
        warning: '#FFC043',
        success: '#026806',
        alertLight: '#FDEDEE',
        successLight: '#CCE5DD',
        newGray: '#C4C4C4',
        lightBlue: '#00AAFF',
        primary: '#D19F00',
        secondary: '#7C99A3',
        secondaryLight: '#DEE4E7',
        atcPrimary: '#00B5EC',
        atcPrimaryHover: '#008DB8',
        secondaryGray: '#EDEDED',
        gwOrange: '#E36F22',
        gwGrey: '#C4C4C4',
        gwBlack: '#000000',
        charcoal: '#1C1C1C',
        darkGrey: '#525151',
        lightGray: '#343434',
        grayHover: '#2a2a2a',
        lime: {
          100: '#DEFF00',
          900: '#424832',
        },
      },
      inset: {
        '1/2': '50%',
        '-1/2': '-50%',
        '2/4': '50%',
        '-2/4': '-50%',
      },
      lineHeight: {
        none: '1',
        tight: '1.2',
        snug: '1.3',
        normal: '1.5',
        relaxed: '1.57',
        loose: '1.66',
        '16': '16px',
        '17': '17px',
        '18': '18px',
        '20': '20px',
        '21': '21px',
        '23': '23px',
        '26': '26px',
        '28': '28px',
        '30': '30px',
        '14': '14px',
        '15': '15px',
        '38': '38px',
        '45': '45px',
        '50': '50px',
        '55': '55px',
        '70': '70px',
        '16r': '1.021rem',
      },
      fontSize: {
        '2xs': '0.625rem', //10px
        xs: '0.75rem', //12px
        xsm: '13px', //13px
        sm: '0.875rem', //14px
        spgs: '0.9375rem', //15px
        base: '1rem', //16px
        smd: '1.125rem', //18px
        md: '1.25rem', //20px
        spg: '1.375rem', //22px
        lg: '1.5rem', //24px
        xl: '2rem', //32px
        '2xl': '2.25rem', //36px
        '3xl': '3rem', //48px
        '4xl': '3.75rem', //60px
        '5xl': '4.5rem', //72px
        '6xl': '5rem', //80px
        'xl-header': '55px',
        'lg-header': '40px',
        'md-header': '36px',
        'sm-header': '32px',
        '22': '1.375rem', //22px
        '25': '1.5625rem', //25px
        '17': '1.063rem', //17px
        '21': '1.3125rem', //21px
        '65': '65px',
        '28': '28px',
        '19': '19px',
      },
      scale: {
        'scale-y-101': '1.01',
      },
      screen: {
        '2xl': '1536px',
      },
      spacing: {
        '1.5': '0.375rem',
        '2.5': '0.625rem',
        '7': '1.875rem',
        '10l': '10px',
        '14': '3.5rem',
        '15': '3.75rem',
        '29': '7.5rem',
        '60': '15rem',
        '14': '0.875rem',
        '23r': '1.469rem',
        '23s': '1.453rem',
        '31s': '1.953rem',
        '20r': '1.25rem',
        '25': '25px',
        '8r': '0.544rem',
        '2.1': '2.1rem',
        '31': '1.9375rem',
        '11': '0.688rem',
        '60r': '60px',
        '63': '63px',
        '33': '33px',
        '44': '44px',
        '14.71': '14.71px',
        '96': '96px',
        '76': '76px',
        '22': '22px',
        '9.5': '9.5px',
        '56': '56px',
        '102': '6.375rem',
        '45': '45px',
        '18.5': '1.156rem',
        '60.5': '3.781rem',
        '20.5': '1.281rem',
        '30.5': '1.906rem',
        '42': '42px',
        '46': '46px',
        '29.5': '29.5px',
        '10.22': '10.22px',
        '30': '30px',
        '32': '32px',
        '21': '21px',
        '18': '18px',
        '9.2': '9.2px',
        '28.5': '28.5px',
        '40.5': '40.5px',
        '12.25': '12.25px',
        '36.25': '36.25px',
        '24.25': '24.25px',
        '100': '6.25rem',
        '80': '5rem',
        '283': '17.688rem',
        '220': '220px',
        '172': '10.75rem',
        '57': '3.563rem',
        '23.82': '1.489rem',
        '10.82': '0.676rem',
        '39': '2.438rem',
        '53': '3.313rem',
        '55': '3.438rem',
        '40': '2.5rem',
        '59': '3.688rem',
        '28.5': '1.781rem',
        '25.5': '1.594rem',
        '15r': '0.938rem',
        '27.5': '1.719rem',
        '38': '2.375rem',
        '42.5': '2.656rem',
        '36.5': '2.281rem',
        '36': '2.25rem',
        '12': '0.75rem',
        '13': '0.813rem',
        '5r': '0.313rem',
        '14.25': '0.891rem',
        '9.25': '0.578rem',
        '199': '12.438rem',
        '108': '6.625rem',
        '18': '1.125rem',
        '9': '0.563rem',
        '3.5rem': '3.5rem',
        '11.21': '0.701rem',
        '110': '6.875rem',
        '92': '5.75rem',
        '52': '3.25rem',
        '72': '4.5rem',
        '8r': '0.5rem',
        '51.5': '3.219rem',
        '50': '3.125rem',
        '157': '157px',
        '54': '54px',
        '65r': '65px',
        '85r': '85px',
        '24r': '24px',
        '90': '5.625rem',
        '48': '3rem',
        '85s': '85.41px',
        '17': '1.063rem',
      },
      letterSpacing: {
        tightest: '-0.1px',
        tight: '-0.5px',
        '2': '-2px',
      },
    },
  },
  corePlugins: {
    container: false,
  },
  plugins: [require('@tailwindcss/aspect-ratio'), container(), buttons(), input(), pill()],
  variants: {
    display: ['responsive', 'active', 'hover', 'focus', 'group-hover'],
    borderWidth: ['responsive', 'active', 'hover', 'focus', 'group-hover'],
    borderColor: ['responsive', 'active', 'hover', 'focus', 'group-hover'],
    boxShadow: ['responsive', 'active', 'hover', 'focus', 'group-hover'],
    textColor: ['responsive', 'active', 'hover', 'focus', 'group-hover'],
    visibility: ['responsive', 'active', 'hover', 'focus', 'group-hover'],
  },
}
