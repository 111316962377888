import getConfig from 'next/config'
import { default as categoriesData } from 'public/categories-data'
import { default as filterCode } from 'public/filtercode'
import { capitalize } from '@/lib/helpers'
import Url from 'url-parse'
import { groupBy } from '@/lib/lodash'
import slugify from 'slugify'
import regions from '@/config/regions'
import { getRegion, getCurrentRegion, getCartLocale } from '@/lib/region-helper'
import { isJsonString, flattenGraphqlArray } from '@/lib/helpers'
import Image from '../components/image/Image'
import { isEmpty } from '@/lib/lodash'
import {
  getProduct as getLambdaProductById,
  getProductImagesEdges,
} from '@/redux/actions/productAction'
import { getCartDiscounts } from '@/redux/actions/cartAction'
import { default as productReviews } from 'public/product-reviews'
import { default as cachedProductSlugs } from 'public/products-localized-slugs'
import { cloneDeep } from '@/lib/lodash'
import axios from 'axios'
import { prepareCouponDataGTM } from '@/lib/gtm'

const { publicRuntimeConfig } = getConfig()

/* Product related helper functions  */

const htmlEntities = {
  nbsp: ' ',
  cent: '¢',
  pound: '£',
  yen: '¥',
  euro: '€',
  copy: '©',
  reg: '®',
  lt: '<',
  gt: '>',
  quot: '"',
  amp: '&',
  apos: "'",
}

const regionsList = ['de', 'fr', 'it', 'es', 'en']

export const unescapeHTML = (str) => {
  return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
    var match

    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode]
      /*eslint no-cond-assign: 0*/
    } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16))
      /*eslint no-cond-assign: 0*/
    } else if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1])
    } else {
      return entity
    }
  })
}

export const formatSSCategories = (categories) => {
  const finalCategories = []
  if (process.env.NEXT_PUBLIC_SITE_REGION == 'us/en-us') {
    let categoriesArray = null
    if (typeof categories == 'string') {
      categoriesArray = categories.split('|')
    } else if (typeof categories == 'object') {
      categoriesArray = categories
    }
    categoriesArray.map((category) => {
      finalCategories.push(parseInt(category))
    })
  } else {
    if (categories) {
      categories.map((category) => {
        finalCategories.push(parseInt(category))
      })
    }
  }
  return finalCategories
}

const formatSSCustomFields = (product) => {
  const finalCustomFields = []
  Object.keys(product).map((item, index) => {
    if (item.startsWith('custom_')) {
      let key = item.split('custom_')[1]
      let value = product[item]
      finalCustomFields.push({
        id: index,
        name: key,
        value: value,
      })
    } else if (
      item.startsWith('en_') ||
      item.startsWith('fr_') ||
      item.startsWith('de_') ||
      item.startsWith('it_') ||
      item.startsWith('es_')
    ) {
      let key = item
      let value = product[item]
      finalCustomFields.push({
        id: index,
        name: key,
        value: value,
      })
    }
  })
  return finalCustomFields
}

export const getFormatedSearchSpringData = (product, productAPIData) => {
  let productVariants = productAPIData.data.variants
  let ssVariants = JSON.parse(product.child_sku_options.replace(/&quot;/g, '"'))
  for (let i = 0; i < productVariants.length; i++) {
    const variant = ssVariants.find((item) => item.id == productVariants[i].id)
    if (variant) {
      if (variant.calculated_price != variant.price) {
        productVariants[i].sale_price = variant.calculated_price
        productVariants[i].calculated_price = variant.calculated_price
        productVariants[i].price = variant.price
      } else {
        productVariants[i].sale_price = null
        productVariants[i].calculated_price = variant.calculated_price
        productVariants[i].price = variant.price
      }
    }
  }
  const productData = {
    categories: '',
    custom_fields: '',
    id: parseInt(product.uid),
    images: productAPIData.data.images,
    options: productAPIData.data.options,
    variants: productAPIData.data.variants,
    ss_variants: JSON.parse(product.child_sku_options.replace(/&quot;/g, '"')),
  }
  productData.categories = formatSSCategories(product.category_ids)
  productData.custom_fields = formatSSCustomFields(product)
  return productData
}

// gets the default image

export const getDefaultImage = (images = []) => {
  const defaultImage =
    images &&
    images.length &&
    images.find((image) => {
      if (image?.node) {
        return image?.node?.isDefault
      } else {
        return image?.isDefault
      }
    })

  return (
    defaultImage || {
      node: {
        urlOriginal: 'https://dummyimage.com/600x400/000/fff',
        altText: '',
        isDefault: true,
      },
    }
  )
}

// get variant images
export const getVariantImages = (images) => {
  let variantImages = []

  if (images.length > 0) {
    let defaultImages = images.filter((image) => {
      let description = JSON.parse(image.description)

      if ((description?.number == '1' && !description.isModel) || description.hover) {
        return image
      }
    })

    let hoverImages = images.filter((image) => {
      let description = JSON.parse(image.description)

      if (description.isModel) {
        return image
      }
    })

    defaultImages = defaultImages.concat(hoverImages)

    if (defaultImages.length > 0) {
      defaultImages.forEach((image) => {
        let description = JSON.parse(image.description)

        variantImages.push({
          color: description.colorName,
          model: description.isModel ? true : false,
          hover: description.hover ? true : false,
          ...image,
        })
      })
    }
    return variantImages
  }
  return variantImages
}

export const getColorCodeByLabel = (label, variants) => {
  let variant = variants.find((variant) => {
    let color = variant.option_values.find((option) => option.option_display_name == 'Color')

    return label == color.label
  })

  let sku = variant && variant?.sku ? (variant?.sku).split('-') : []

  if (sku.length > 0) {
    return sku[1]
  } else {
    return null
  }
}

export const getVariantImagesByColorCode = (variant, images) => {
  let variantSku = variant?.sku?.split('-')

  if (variantSku) {
    let colorCode = variantSku[1]

    if (images.length > 0) {
      let variantImages = images
        .map((image) => {
          let description

          if (image.node) {
            description = JSON.parse(image.node.altText)

            return {
              ...image.node,
              description,
            }
          } else {
            description = JSON.parse(image.altText)

            return {
              ...image,
              description,
            }
          }
        })
        .filter((image) => image.description.colorCode == colorCode)

      return variantImages
    }
  }

  return images
}

// gets all images except the default
export const getOtherImages = (images) => {
  if (images.length > 0) {
    if (images[0]?.node) {
      return images.filter((image) => !image.node.isDefault)
    }

    return images.filter((image) => !image.isDefault)
  }

  return [
    {
      node: {
        urlOriginal: 'https://dummyimage.com/600x400/000/fff',
        altText: '',
        isDefault: false,
      },
    },
  ]
}

// get the product sale price
export const getSalePrice = (prices) => {
  return prices.salePrice != null
    ? prices.salePrice.value.toFixed(2)
    : prices.price.value.toFixed(2)
}

// get the product retail price
export const getRetailPrice = (prices) => {
  return prices.retailPrice != null
    ? prices.retailPrice.value.toFixed(2)
    : prices.price.value.toFixed(2)
}

// get default price if not base price is set //unused
export const getDefaultPrice = (variants) => {
  let defaultPrice = null

  let salePrice = 0

  if (variants.length <= 0) {
    return defaultPrice
  }

  variants.forEach((variant, index) => {
    if (index == '0') {
      defaultPrice = variant.price
    }

    if (variant.salePrice > salePrice) {
      defaultPrice = variant.price
    }
  })

  return defaultPrice
}

export const onSale = (prices) => {
  return prices.salePrice != null ? true : false
}

export const getDiscount = (retailPrice, salePrice) => {
  if (salePrice != null && salePrice != '0') {
    const discount = (100 * (retailPrice - salePrice)) / retailPrice

    const discountRem = discount % 1

    if (discountRem >= 0.9) {
      return parseInt(Math.round(discount).toFixed(0))
    } else {
      return parseInt(Math.floor(discount).toFixed(0))
    }
  }
  return 0
}

export const getDiscountedPrice = (regularPrice, discount) => {
  const discountedPrice =
    parseFloat(regularPrice) - (parseFloat(regularPrice) * parseFloat(discount)) / 100

  return discountedPrice.toFixed(2)
}

export const getCategories = (ids) => {
  let catArr = new Array()
  ids.map((id) => {
    categoriesData.data.map((cat) => {
      if (cat.id == id) {
        catArr.push(cat)
      }
    })
  })
  return catArr
}

export const getCustomFieldValue = (data, name) => {
  const field_value = data.filter((d) => d.name == name)
  return field_value
}

export const filterColorOptions = (colorOptions, product) => {
  let variantColors = []
  let newVariants = [...product.variantsData].filter(
    (variant) => !variant.purchasing_disabled && variant.inventory_level > 0
  )
  let newSortedArr = newVariants.sort((a, b) => a.id - b.id)
  newSortedArr.forEach((variant) => {
    let color = variant.option_values.find((option) => option.option_display_name === 'Color')
    if (!isEmpty(color)) {
      if (!variantColors.includes(color.label)) {
        variantColors.push(color.label)
      }
    }
  })

  //fallback when all are oos
  if (variantColors.length == 0) {
    let newVariants = [...product.variantsData].filter((variant) => !variant.purchasing_disabled)
    let newSortedArr = newVariants.sort((a, b) => a.id - b.id)
    newSortedArr.forEach((variant) => {
      let color = variant.option_values.find((option) => option.option_display_name === 'Color')
      if (!isEmpty(color)) {
        if (!variantColors.includes(color.label)) {
          variantColors.push(color.label)
        }
      }
    })
  }

  let finalData = colorOptions.option_values.filter((option) =>
    variantColors.find((variantColor) => variantColor === option.label)
  )
  return finalData
}

export const filterColorOptionsV2 = (colorOptions, product, sortRule = null) => {
  let variantColors = []
  let saleSortedColors = []
  let newVariants = [...product.variantMetaCollection].filter(
    (variant) => !variant.purchasing_disabled && variant.inventory_level > 0
  )
  let newSortedArr = newVariants.sort((a, b) => a.id - b.id)
  let priceSortedColor = []
  let firstSortRule = sortRule ? Object.keys(sortRule)[0] : null

  newSortedArr.forEach((variant) => {
    let color = variant.option_value
    if (
      sortRule &&
      sortRule.lowtohighprice &&
      sortRule.lowtohighprice == 'true' &&
      firstSortRule == 'lowtohighprice'
    ) {
      let localePrice = getLocalePriceV2(variant)
      if (localePrice == null) {
        localePrice = {
          salePrice: variant.sale_price == null ? 0 : variant.sale_price,
          price: variant.price,
          onSale: variant.sale_price == null || variant.sale_price == 0 ? false : true,
        }
      }

      saleSortedColors.push({
        color: color,
        salePrice: localePrice.salePrice == 0 ? localePrice.price : localePrice.salePrice,
      })

      saleSortedColors = saleSortedColors.filter((item, index, self) => {
        return (
          index ===
          self.findIndex((obj) => obj.color === item.color && obj.salePrice === item.salePrice)
        )
      })

      priceSortedColor.push({
        color: color,
        price: localePrice.onSale ? localePrice.salePrice : localePrice.price,
        id: variant.id,
      })
    }

    if (!isEmpty(color)) {
      if (!variantColors.includes(color)) {
        variantColors.push(color)
      }
    }
  })
  //fallback when all are oos
  if (variantColors.length == 0) {
    newSortedArr.forEach((variant) => {
      if (!variant.purchasing_disabled) {
        let color = variant.option_value
        if (!isEmpty(color)) {
          if (!variantColors.includes(color)) {
            variantColors.push(color)
          }
        }
      }
    })
  }

  let finalData = colorOptions.option_values.filter((option) =>
    variantColors.find((variantColor) => variantColor === option.label)
  )

  if (
    sortRule &&
    sortRule.lowtohighprice &&
    sortRule.lowtohighprice == 'true' &&
    firstSortRule == 'lowtohighprice'
  ) {
    finalData = finalData.sort((a, b) => {
      const colorA = saleSortedColors.find((obj) => obj.color === a.label)
      const colorB = saleSortedColors.find((obj) => obj.color === b.label)

      return colorA.salePrice - colorB.salePrice
    })
    let uniqueObjects = priceSortedColor.filter((obj, index, self) => {
      const color = obj.color
      return !self.slice(0, index).some((prevObj) => prevObj.color === color)
    })
    uniqueObjects.sort((a, b) => a.id - b.id)
    uniqueObjects.sort((a, b) => {
      const priceA = a.price
      const priceB = b.price
      if (priceA === 0 && priceB !== 0) {
        return 1
      }
      if (priceA !== 0 && priceB === 0) {
        return -1
      }
      return priceA - priceB
    })
    finalData.sort((a, b) => {
      const colorA = a.label
      const colorB = b.label

      const indexA = uniqueObjects.findIndex((obj) => obj.color === colorA)
      const indexB = uniqueObjects.findIndex((obj) => obj.color === colorB)

      return indexA - indexB
    })
  }

  if (
    sortRule &&
    sortRule.colororder &&
    sortRule.colororder != '' &&
    firstSortRule == 'colororder'
  ) {
    const sortOrder = sortRule['colororder'].split('|')
    let initialVariantCollection = [...product.variantCollection]
    for (let i = 0; i < initialVariantCollection.length; i++) {
      let colorCode = initialVariantCollection[i]['sizes'][0]['sku'].split('-')[1]
      let colorName = initialVariantCollection[i]['color']
      let foundObject = finalData.find((obj) => obj.label.toLowerCase() === colorName.toLowerCase())
      if (foundObject) {
        foundObject.colorCode = colorCode
      }
    }
    finalData = finalData.sort((a, b) => {
      let indexA = sortOrder.indexOf(a.colorCode)
      let indexB = sortOrder.indexOf(b.colorCode)
      if (indexA === -1 && indexB === -1) {
        return 0
      } else if (indexA === -1) {
        return 1
      } else if (indexB === -1) {
        return -1
      }
      return indexA - indexB
    })
  }
  return finalData
}

export const sortProductSizeBasedOnColor = (sortArray, productSizes) => {
  // Create a mapping of size labels to corresponding sort orders
  const sortOrderMap = productSizes?.reduce((map, item) => {
    map[item?.label] = item?.sort_order
    return map
  }, {})

  // Sort sortArray based on the sort order of corresponding labels in productSizes
  sortArray?.sort((a, b) => sortOrderMap[a?.size] - sortOrderMap[b?.size])

  // Check if all sort_order fields in productSizes are 0
  const allSortOrdersZero = productSizes?.every((item) => item?.sort_order === 0)

  // If all sort_order fields are 0, return sortArray based on productSizes list
  if (allSortOrdersZero) {
    return productSizes?.map((item) => sortArray?.find((product) => product?.size === item?.label))
  }

  // Otherwise, return the sorted soertArray
  return sortArray
}

function replaceSpecialChars(str) {
  return str
    .replace(/[^\w\s]/gi, '-') // Replace special characters with '-'
    .replace(/\s+/g, '-') // Replace whitespace with '-'
    .toLowerCase() // Convert to lowercase
}

export const displayColorSwatch = (product, onClick, visibleColorList) => {
  let colorCode = '#000000'
  const colorOptions = product.options.find((option) => option.display_name == 'Color')
  const selectedColor = product.selectedVariant.color
  const colorBoxes = []
  if (isEmpty(colorOptions)) return <></>
  visibleColorList.forEach((color, index) => {
    let colorOpt = colorOptions.option_values.find((option) => option.label == color)
    let isSelected = selectedColor == color ? true : false
    if (color.includes('/')) {
      let colorLabel = color.split('/')
      let primaryColor = filterCode.data[replaceSpecialChars(colorLabel[0])]
      let secondaryColor = filterCode.data[replaceSpecialChars(colorLabel[1])]

      colorBoxes.push(
        <div
          title={color}
          key={`color-${index}-${colorOpt.id}`}
          className={`color-image-filter-wrap flex border rounded-full cursor-pointer 2xs:mr-10.22 2xs:ml-0 md:ml-10.22 md:mr-0`}
          style={isSelected ? { borderColor: colorCode } : { borderColor: 'transparent' }}
          onClick={() => onClick(color, product)}
        >
          <div
            className="color-image-filter-item rounded-full cursor-pointer relative color-image-rotate"
            data-label={color}
            data-type="color"
          >
            <div className={`relative color-image-rotate`}>
              <Image
                unoptimized={true}
                src={`${publicRuntimeConfig.basePath}/images/filters/${primaryColor}h.svg`}
                alt={primaryColor}
                height={22}
                width={20}
                className="swatch-image swatch-half-image rounded-full"
              />
              <Image
                unoptimized={true}
                src={`${publicRuntimeConfig.basePath}/images/filters/${secondaryColor}h.svg`}
                alt={secondaryColor || 'default color'}
                height={22}
                width={20}
                className="swatch-image rounded-full"
              />
            </div>
          </div>
        </div>
      )
    } else {
      let colorLabel = filterCode.data[replaceSpecialChars(color)]

      colorBoxes.push(
        <div
          title={color}
          key={`color-${index}-${colorOpt.id}`}
          className={`color-image-filter-wrap flex border rounded-full cursor-pointer 2xs:mr-10.22 2xs:ml-0 md:ml-10.22 md:mr-0`}
          style={isSelected ? { borderColor: colorCode } : { borderColor: 'transparent' }}
          onClick={() => onClick(color, product)}
        >
          <div
            className={`color-image-filter-item rounded-full cursor-pointer`}
            data-label={color}
            data-type="color"
          >
            <Image
              unoptimized={true}
              src={`${publicRuntimeConfig.basePath}/images/filters/${colorLabel}.png`}
              alt={color}
              height={22}
              width={20}
              className="swatch-image rounded-full"
            />
          </div>
        </div>
      )
    }
  })
  return colorBoxes
}

export const displayColorOptionsV2 = (
  productV2,
  onClick,
  visibleColorList = [],
  colorFiltersTrans,
  isProductCompare,
  sort_rules = ''
) => {
  const sortRule = sort_rules != '' ? JSON.parse(sort_rules) : null
  const colorOptions = productV2.options.find((option) => option.display_name == 'Color')
  const colorBoxes = []
  const base_path = publicRuntimeConfig.basePath
  if (isEmpty(colorOptions)) return <></>

  const filteredColorOptions = filterColorOptionsV2(colorOptions, productV2, sortRule)

  if (filteredColorOptions.length > 0) {
    filteredColorOptions.forEach((option, index) => {
      if (visibleColorList && visibleColorList.includes(option.id)) {
        let isSelected = false
        let colorCode = '#000000'

        if (productV2.selectedVariant !== null) {
          if (productV2.selectedVariant.color === option.label) {
            isSelected = true
          }
        }

        if (isProductCompare && option.value_data && option.value_data.colors.length > 0) {
          colorCode = option.value_data.colors[0]
        }

        if (option.label.includes('/')) {
          let color = option.label.split('/')
          let primaryColor = filterCode.data[color[0].replace(/ /g, '-').toLowerCase().trim()]
          let secondaryColor = filterCode.data[color[1].replace(/ /g, '-').toLowerCase().trim()]

          colorBoxes.push(
            <div
              title={option.label}
              key={`color-${index}-${option.id}`}
              className={`color-image-filter-wrap flex border rounded-full cursor-pointer 2xs:mr-10.22 2xs:ml-0 md:ml-10.22 md:mr-0`}
              style={isSelected ? { borderColor: colorCode } : { borderColor: 'transparent' }}
              onClick={() => onClick(option.label, productV2)}
            >
              <div
                className="color-image-filter-item rounded-full cursor-pointer relative color-image-rotate"
                data-label={
                  base_path.includes('en-us')
                    ? option.label
                    : colorFiltersTrans
                    ? colorFiltersTrans[option.label]
                    : option.label
                }
                data-type="color"
              >
                <div className={`relative color-image-rotate`}>
                  <Image
                    unoptimized={true}
                    src={`${publicRuntimeConfig.basePath}/images/filters/${primaryColor}h.svg`}
                    alt={primaryColor}
                    height={22}
                    width={20}
                    className="swatch-image swatch-half-image rounded-full"
                  />
                  <Image
                    unoptimized={true}
                    src={`${publicRuntimeConfig.basePath}/images/filters/${secondaryColor}h.svg`}
                    alt={secondaryColor || 'default color'}
                    height={22}
                    width={20}
                    className="swatch-image rounded-full"
                  />
                </div>
              </div>
            </div>
          )
        } else {
          let color = filterCode.data[replaceSpecialChars(option.label)]

          colorBoxes.push(
            <div
              title={option.label}
              key={`color-${index}`}
              className={`color-image-filter-wrap flex border rounded-full cursor-pointer 2xs:mr-10.22 2xs:ml-0 md:ml-10.22 md:mr-0`}
              style={isSelected ? { borderColor: colorCode } : { borderColor: 'transparent' }}
              onClick={() => onClick(option.label, productV2)}
            >
              <div
                className={`color-image-filter-item rounded-full cursor-pointer`}
                data-label={
                  base_path.includes('en-us')
                    ? option.label
                    : colorFiltersTrans
                    ? colorFiltersTrans[option.label]
                    : option.label
                }
                data-type="color"
              >
                <Image
                  unoptimized={true}
                  src={`${publicRuntimeConfig.basePath}/images/filters/${color}.png`}
                  alt={color}
                  height={22}
                  width={20}
                  className="swatch-image rounded-full"
                />
              </div>
            </div>
          )
        }
      }
    })

    return colorBoxes
  }

  return <></>
}

// display product colors based on product variant
export const displayVariantColors = (product, onClick) => {
  const variants = product.variantCollection
  const variantColors = []

  const variantBoxes = []

  //get the color values
  variants.forEach((variant) => {
    let count = 0
    for (let item in variant.sizes) {
      if (variant.sizes[item].inventory > 0) {
        count = count + 1
        break
      }
    }
    if (count > 0) {
      variantColors.push(variant.color)
    }
  })
  // check if variant colors are available
  if (variantColors.length > 0) {
    variantColors.forEach((variantColor, index) => {
      let isSelected = false

      if (product.selectedVariant !== null) {
        if (product.selectedVariant.color === variantColor) {
          isSelected = true
        }
      }

      if (variantColor.includes('/')) {
        let color = variantColor.split('/')

        let primaryColor = filterCode.data[capitalize(color[0].replace(/ /g, ''))]

        let secondaryColor = filterCode.data[capitalize(color[1].replace(/ /g, ''))]

        variantBoxes.push(
          <div
            key={`color-${index}`}
            className={`color-image-filter-wrap flex border border-gray-200 cursor-pointer mr-2 mb-2 ${
              isSelected ? 'selected-color' : ''
            }`}
            onClick={() => onClick(variantColor, product)}
          >
            <div
              className="color-image-filter-item cursor-pointer"
              data-label={variantColor}
              data-type="color"
            >
              <div className="relative color-image-rotate">
                <img
                  src={`/images/filters/${primaryColor}h.svg`}
                  alt={primaryColor}
                  className="swatch-image swatch-half-image"
                />
                <img
                  src={`/images/filters/${secondaryColor}h.svg`}
                  alt={secondaryColor}
                  className="swatch-image"
                />
              </div>
            </div>
          </div>
        )
      } else {
        let splitVal = ''
        let joinData = ''
        let color = ''
        if (variantColor.includes(' ')) {
          splitVal = variantColor.split(' ')
          let i = 0
          splitVal.map((item) => {
            if (i != 0) {
              let nameCapitalized = item.charAt(0).toUpperCase() + item.slice(1)
              splitVal[i] = nameCapitalized
            } else if (i == 0) {
              let nameLowercase = item.charAt(0).toLowerCase() + item.slice(1)
              splitVal[i] = nameLowercase
            }
            i = i + 1
          })
          joinData = splitVal.join('')
        }
        if (joinData != '') {
          color = filterCode.data[joinData]
        } else {
          color = filterCode.data[capitalize(variantColor.replace(/ /g, ''))]
        }
        variantBoxes.push(
          <div
            key={`color-${index}`}
            className={`color-image-filter-wrap flex border border-gray-200 cursor-pointer mr-2 mb-2 ${
              isSelected ? 'selected-color' : ''
            }`}
            onClick={() => onClick(variantColor, product)}
          >
            <div
              className="color-image-filter-item cursor-pointer"
              data-label={variantColor}
              data-type="color"
            >
              <img src={`/images/filters/${color}.png`} alt={color} className="swatch-image" />
            </div>
          </div>
        )
      }
    })

    return variantBoxes
  }

  return ''
}

export const displayPrice = (isAuthenticated, productData, customerGroup, price, disableATC) => {
  const customerGroupDiscountRules = getGroupDiscountRules(customerGroup)
  const groupNameInfo =
    customerGroup && customerGroup.name ? getGroupNameInfo(customerGroup.name) : null

  const product = {
    ...productData,
    id: productData.entityId,
  }
  const discountRule = getDefaultDiscountRule(customerGroupDiscountRules, product)

  if (disableATC) {
    return 'Out of Stock'
  }

  if (isAuthenticated) {
    // rule to check which price is higher.
    // if sale price is higher return that price instead of group discount
    if (getDiscount(price.regularPrice, price.salePrice) > formatDiscount(discountRule?.amount)) {
      return price.calculatedPrice.toFixed(2)
    }

    if (customerGroup != null && discountRule != null && allowGroupDiscount(groupNameInfo)) {
      return getDiscountedPrice(price.regularPrice, formatDiscount(discountRule?.amount))
    }
  }

  return price.calculatedPrice.toFixed(2)
}

// set product image url. If no variant is selected the default image is displayed
export const setProductImageUrl = (product) => {
  let retunImg = ''
  if (product.selectedVariant) {
    let variantImage = product.variantImages.find(
      (image) => product.selectedVariant.color === image.color
    )
    retunImg = isEmpty(variantImage) ? product.selectedVariant.image : variantImage.url_standard
  } else if (product.variantCollection && product.variantCollection[0]) {
    retunImg = product.variantCollection[0]['image']
  } else {
    retunImg = product.image.url
  }
  return retunImg
}

export const getColorVariants = (variants) => {
  let colorVariants = []

  variants.forEach((variant) => {
    variant.option_values.forEach((option) => {
      if (option.option_display_name === 'Color') {
        if (!colorVariants.includes(option.label)) {
          colorVariants.push(option.label)
        }
      }
    })
  })

  return colorVariants
}

export const getColorOptions = (options) => {
  if (isEmpty(options)) {
    return []
  }

  let colorOptions = options.find((option) => option.display_name === 'Color')

  if (isEmpty(colorOptions)) {
    return []
  }
  return colorOptions.option_values
}

export const getProductUrl = (url) => {
  const url_path = new Url(url)
  return url_path.pathname
}

// Get the discount rules from the customer group
export const getGroupDiscountRules = (customerGroup) => {
  let siteWide = {
    rules: [],
  }
  let category = {
    rules: [],
  }
  let product = {
    rules: [],
  }
  let groupDiscount = {
    siteWide,
    category,
    product,
  }

  if (customerGroup != null) {
    customerGroup.discount_rules.forEach((rule) => {
      switch (rule.type) {
        case 'all':
          siteWide.rules.push(rule)
          break
        case 'category':
          category.rules.push(rule)
          break
        case 'product':
          product.rules.push(rule)
          break
        default:
      }
    })
  }

  return groupDiscount
}
// Get the default discount rule to apply
// Discount rules heirarchy product > category > all
export const getDefaultDiscountRule = (customerGroupDiscountRules, product) => {
  if (customerGroupDiscountRules.product.rules.length > 0) {
    let rule = customerGroupDiscountRules.product.rules.find(
      (rule) => rule.product_id == product.id
    )
    if (typeof rule !== 'undefined') {
      return rule
    }
  }

  if (customerGroupDiscountRules.category.rules.length > 0) {
    if (product.categories != null) {
      let rule = null
      product.categories.every((categoryId) => {
        let found = customerGroupDiscountRules.category.rules.find(
          (rule) => rule.category_id == categoryId
        )

        if (typeof found !== 'undefined') {
          rule = found
          return false
        }

        return true
      })

      if (rule != null) {
        return rule
      }
    }
  }

  if (customerGroupDiscountRules.siteWide.rules.length > 0) {
    return customerGroupDiscountRules.siteWide.rules[0]
  }

  return null
}

// format group discount
export const formatDiscount = (discount) => {
  if (typeof discount === 'undefined') return false
  // const partials = discount.split('.')
  // return parseInt(partials[0])
  return Number(parseInt(discount).toFixed(2))
}

// get category ids from category names
export const getCategoryIds = (categories, categoryNames) => {
  let categoryIds = []
  categoryNames.forEach((categoryName) => {
    let name = categoryName.replace('&amp;', '&')

    let categoryId = getCategoryId(name, categories)

    if (categoryId != null) {
      categoryIds.push(categoryId.id)
    }
  })

  return categoryIds
}

// get the category id from category nav
export const getCategoryId = (name, categories) => {
  const categoryId = categories.reduce((a, item) => {
    if (a) return a
    if (item.name == name) return item
    if (item.children) return getCategoryId(name, item.children)
  }, null)

  return categoryId
}

export const validCategories = () => {
  const categories = [
    'Bib Shorts',
    'Gloves & Hats',
    'Socks & Accessories',
    'Jerseys',
    'Shorts',
    'Jackets & Vests',
    'Baselayers',
    'Tights',
  ]
  return categories
}

export const getLowestSalePrice = (variants) => {
  let prices = {
    regularPrice: null,
    salePrice: null,
    id: null,
    color: null,
    size: null,
  }
  let priceArray = []
  variants &&
    variants.forEach((variant, index) => {
      if (!variant.purchasing_disabled && variant.inventory_level > 0) {
        if (index == 0) {
          prices.regularPrice = variant.price
          prices.salePrice = variant.sale_price
          prices.id = variant.id
          prices.color = variant.option_values.find(
            (item) => item.option_display_name == 'Color'
          ).label
          prices.size = variant.option_values.find(
            (item) => item.option_display_name == 'Size'
          ).label
          priceArray.push(prices)
        } else {
          if (variant.sale_price != null) {
            if (prices.salePrice == null || prices.salePrice > variant.sale_price) {
              prices.salePrice = variant.sale_price
              prices.regularPrice = variant.price
              prices.id = variant.id
              prices.color = variant.option_values.find(
                (item) => item.option_display_name == 'Color'
              ).label
              prices.size = variant.option_values.find(
                (item) => item.option_display_name == 'Size'
              ).label
              priceArray.push(prices)
            }
          } else {
            prices.regularPrice = variant.price
            prices.salePrice = variant.sale_price
            prices.id = variant.id
            prices.color = variant.option_values.find(
              (item) => item.option_display_name == 'Color'
            ).label
            prices.size = variant.option_values.find(
              (item) => item.option_display_name == 'Size'
            ).label
            priceArray.push(prices)
          }
        }
      }
    })
  return prices
}

const generateList = (metaData) => {
  const translatedList = []
  metaData.map((meta) => {
    // console.log(meta.key.toLowerCase())
    if (regionsList.includes(meta.key.toLowerCase())) {
      const translationCategory = JSON.parse(meta.value)
      translationCategory.lang = meta.key
      translatedList.push(translationCategory)
    }
  })
  return translatedList
}
export const getAllCategoryPaths = () => {
  const categoryPaths = new Array()
  categoriesData.data.map((category) => {
    let pushData = {
      title: category.name,
      slug: category.url,
    }
    if (category?.metadata?.length > 0) {
      const list = generateList(category.metadata)
      pushData.translation = list
    }
    categoryPaths.push(pushData)
  })
  return categoryPaths
}

export const getStoreCategoryPaths = () => {
  const region = getCurrentRegion()
  const urlKey = region.domain == 'en-us' ? 'url' : `${region.localePrefix}_url`
  const categoryPaths = []
  categoriesData.data.map((category) => {
    if (category['is_visible'] && category[urlKey] != '') {
      categoryPaths.push({ slug: category[urlKey] })
    }
  })
  return categoryPaths
}

export const getCategoryNameByUrl = (url) => {
  const category = categoriesData.data.find((item) => item.url == url)?.name || ''
  return category
}

export const getTranslatedNameByUrl = (url) => {
  const categoryName = new Array()
  const path = publicRuntimeConfig.basePath
  const country = regions.default.find((item) => path.includes(item.domain))
  getAllCategoryPaths().map((category) => {
    if (
      regionsList.includes(country.localePrefix) &&
      category.translation &&
      category.translation.length > 0
    ) {
      // console.log(category)
      const keyLang = country.localePrefix.toUpperCase()
      const dataList = category.translation.find((i) => i.lang == keyLang)
      if (category.slug.replace(/^\/|\/$/g, '') == url.replace(/^\/|\/$/g, '') && dataList) {
        categoryName.push(dataList.description)
      }
    }
    if (category.slug.replace(/^\/|\/$/g, '') == url.replace(/^\/|\/$/g, '')) {
      categoryName.push(category.title)
    }
  })
  return categoryName
}

export const getGroupedCategories = (pathR) => {
  const path = publicRuntimeConfig.basePath
  const country = regions.default.find((item) => path.includes(item.domain))
  const language = country.localePrefix
  const categoryPaths = []
  getAllCategoryPaths().map((category) => {
    if (
      country.localePrefix == 'en' &&
      pathR.replace(/^\/|\/$/g, '') == category.slug.replace(/^\/|\/$/g, '')
    ) {
      // console.log(pathR, category.slug)
      categoryPaths.push(category)
    } else if (
      regionsList.includes(country.localePrefix) &&
      category.translation &&
      category.translation.length > 0
    ) {
      let configPath = category.translation.find((i) => i.lang == language.toUpperCase())
      if (configPath && pathR.replace(/^\/|\/$/g, '') == configPath.url.replace(/^\/|\/$/g, '')) {
        // console.log(pathR, category, configPath.url)
        categoryPaths.push(category)
      }
    }
  })
  return categoryPaths
}

export const getStoreFilters = (filters) => {
  const path = publicRuntimeConfig.basePath
  const country = regions.default.find((item) => path.includes(item.domain))
  const language = country.localePrefix
  const filtersList = []
  filters.map((item) => {
    if (
      !item.field.includes('mfield') &&
      !item.field.includes('price') &&
      !item.field.includes('_size') &&
      !item.field.includes('ss_variantsizefamilies')
    ) {
      filtersList.push(item)
    }
  })
  if (path.includes('en-us')) {
    const priceFilter = filters.find((item) => item.field == 'ss_price_range')
    priceFilter && filtersList.push(priceFilter)
    const sizeFilter = filters.find((item) => item.field == 'ss_variantsizefamilies')
    sizeFilter && filtersList.push(sizeFilter)
  } else {
    const priceFilter = filters.find(
      (item) => item.field == `ss_${country.isoCode.toLowerCase()}_price`
    )
    priceFilter && filtersList.push(priceFilter)

    const sizeFilter = filters.find((item) => item.field == 'ss_variantsizefamilies')
    sizeFilter && filtersList.push(sizeFilter)

    // disabled multi size filter logic
    /*  if (path.includes('en-uk')) {
      const sizeFilter = filters.find((item) => item.field == `ss_uk_size`)
      sizeFilter && filtersList.push(sizeFilter)
    } else {
      const sizeFilter = filters.find((item) => item.field == `ss_${language}_size`)
      if (sizeFilter) {
        filtersList.push(sizeFilter)
      } else {
        let sizefilters = filters.find((item) => item.field == `ss_eu_size`)
        sizefilters && filtersList.push(sizefilters)
      }
    } */
  }
  return filtersList
}

export const getInitialPriceRange = (filters) => {
  const region = getCurrentRegion()
  if (region.domain == 'en-us') {
    const priceFilter = filters.find((item) => item.field == 'ss_price_range')
    return priceFilter || null
  } else {
    const priceFilter = filters.find(
      (item) => item.field == `ss_${region.isoCode.toLowerCase()}_price`
    )
    return priceFilter || null
  }
}

export const getSortOptions = (sort) => {
  const finalOptions = []
  const path = publicRuntimeConfig.basePath
  const country = regions.default.find((item) => path.includes(item.domain))
  sort.map((option) => {
    // console.log(option, option.field.includes('price'))
    if (option.field.includes('price')) {
      if (path.includes('en-us') && option.field == 'ss_price') {
        finalOptions.push(option)
      } else if (
        path.includes(country.domain) &&
        option.field == `ss_${country.isoCode.toLowerCase()}_calc_price`
      ) {
        finalOptions.push(option)
      }
    } else {
      finalOptions.push(option)
    }
  })
  return finalOptions
}

export const getSearchSpringKeys = () => {
  let keys = {
    NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL: '',
    NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL: '',
    NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL: '',
    NEXT_PUBLIC_TRENDING_URL: '',
    NEXT_PUBLIC_SEARCH_SPRING_SITEID: '',
  }
  const path = publicRuntimeConfig.basePath
  const country = regions.default.find((item) => path.includes(item.domain))
  const counrtyLang = country.localePrefix.toUpperCase()
  if (counrtyLang === 'ES') {
    keys = {
      NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL: process.env.NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL_EU_ES,
      NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL: process.env.NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL_EU_ES,
      NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL:
        process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL_EU_ES,
      NEXT_PUBLIC_TRENDING_URL: process.env.NEXT_PUBLIC_TRENDING_URL_EU_ES,
      NEXT_PUBLIC_SEARCH_SPRING_SITEID: process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID_EU_ES,
    }
  } else if (counrtyLang === 'FR') {
    keys = {
      NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL: process.env.NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL_EU_FR,
      NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL: process.env.NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL_EU_FR,
      NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL:
        process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL_EU_FR,
      NEXT_PUBLIC_TRENDING_URL: process.env.NEXT_PUBLIC_TRENDING_URL_EU_FR,
      NEXT_PUBLIC_SEARCH_SPRING_SITEID: process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID_EU_FR,
    }
  } else if (counrtyLang === 'IT') {
    keys = {
      NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL: process.env.NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL_EU_IT,
      NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL: process.env.NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL_EU_IT,
      NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL:
        process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL_EU_IT,
      NEXT_PUBLIC_TRENDING_URL: process.env.NEXT_PUBLIC_TRENDING_URL_EU_IT,
      NEXT_PUBLIC_SEARCH_SPRING_SITEID: process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID_EU_IT,
    }
  } else if (counrtyLang === 'DE') {
    keys = {
      NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL: process.env.NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL_EU_DE,
      NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL: process.env.NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL_EU_DE,
      NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL:
        process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL_EU_DE,
      NEXT_PUBLIC_TRENDING_URL: process.env.NEXT_PUBLIC_TRENDING_URL_EU_DE,
      NEXT_PUBLIC_SEARCH_SPRING_SITEID: process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID_EU_DE,
    }
  } else if (counrtyLang === 'EN' && country.region === 'EU') {
    keys = {
      NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL: process.env.NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL_EU_EN,
      NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL: process.env.NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL_EU_EN,
      NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL:
        process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL_EU_EN,
      NEXT_PUBLIC_TRENDING_URL: process.env.NEXT_PUBLIC_TRENDING_URL_EU_EN,
      NEXT_PUBLIC_SEARCH_SPRING_SITEID: process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID_EU_EN,
    }
  } else {
    keys = {
      NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL: process.env.NEXT_PUBLIC_SEARCH_SPRING_SEARCH_URL,
      NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL: process.env.NEXT_PUBLIC_CONTENT_FEED_SEARCH_URL,
      NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL: process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATION_URL,
      NEXT_PUBLIC_TRENDING_URL: process.env.NEXT_PUBLIC_TRENDING_URL,
      NEXT_PUBLIC_SEARCH_SPRING_SITEID: process.env.NEXT_PUBLIC_SEARCH_SPRING_SITEID,
    }
  }
  return keys
}

export const getLocalePriceForGA = (ssMetaCollection, variantId, code) => {
  const vId = variantId
  const selectedOption = ssMetaCollection.find((item) => item.id == vId)
  if (selectedOption) {
    return {
      price:
        selectedOption[`variant_mfield_variant_details_${code.toLowerCase()}_price`]?.replace(
          /[{""}]/g,
          ''
        ) || undefined,
      salePrice:
        selectedOption[`variant_mfield_variant_details_${code.toLowerCase()}_sale_price`]?.replace(
          /[{""}]/g,
          ''
        ) || undefined,
    }
  }
  return null
}

export const getLocalePriceForGAV2 = (metafields, variantId, code) => {
  const vId = variantId
  const selectedOption = ssMetaCollection.find((item) => item.id == vId)
  if (selectedOption) {
    return {
      price:
        selectedOption[`variant_mfield_variant_details_${code.toLowerCase()}_price`]?.replace(
          /[{""}]/g,
          ''
        ) || undefined,
      salePrice:
        selectedOption[`variant_mfield_variant_details_${code.toLowerCase()}_sale_price`]?.replace(
          /[{""}]/g,
          ''
        ) || undefined,
    }
  }
  return null
}

export const getLocalePrice = (product, variant) => {
  // console.log(product)
  const path = publicRuntimeConfig.basePath
  const country = regions.default.find((item) => path.includes(item.domain))
  const vId = variant.id
  const selectedOption = product?.variantMetaCollection?.find((item) => item.id == vId)
  if (!path.includes('en-us') && selectedOption) {
    let price =
      selectedOption[
        `variant_mfield_variant_details_${country.isoCode.toLowerCase()}_price`
      ]?.replace(/[{""}]/g, '') || undefined
    if (price == undefined) {
      price = 0
    }
    let salePrice =
      selectedOption[
        `variant_mfield_variant_details_${country.isoCode.toLowerCase()}_sale_price`
      ]?.replace(/[{""}]/g, '') || undefined
    if (salePrice == undefined) {
      salePrice = 0
    }
    return {
      price: parseFloat(price),
      salePrice: salePrice == 'null' ? 0 : parseFloat(salePrice),
      onSale: salePrice == 'null' ? false : true,
    }
  } else if (!path.includes('en-us') && product.variants && product.variants.length > 0) {
    const findVariant = product.variants.find((varint) => varint.id == variant.id)
    // console.log(findVariant)
    if (findVariant.metafields && findVariant.metafields.length > 0) {
      let salePrice = findVariant.metafields
        .find((varnt) => varnt.key == `${country.isoCode.toLowerCase()}_sale_price`)
        ?.value?.replace(/[{""}]/g, '')
      let price = findVariant.metafields
        .find((varnt) => varnt.key == `${country.isoCode.toLowerCase()}_price`)
        ?.value?.replace(/[{""}]/g, '')
      return {
        price: parseFloat(price),
        salePrice: salePrice == 'null' ? 0 : parseFloat(salePrice),
        onSale: salePrice == 'null' ? false : true,
      }
    }
  }
  return null
}

export const getLocalePriceV2 = (variant) => {
  const country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  if (country.domain != 'en-us') {
    let price = variant[`${country.isoCode.toLowerCase()}_price`] || undefined
    if (price == undefined) {
      price = 0
    }
    let salePrice = variant[`${country.isoCode.toLowerCase()}_sale_price`] || undefined
    if (salePrice == undefined) {
      salePrice = 0
    }

    return {
      price: parseFloat(price),
      salePrice: salePrice == 'null' ? 0 : parseFloat(salePrice),
      onSale: salePrice == 'null' ? false : true,
    }
  }
  return null
}

export const getCategoryNamesByIds = (ids) => {
  return categoriesData.data
    .filter((category) => {
      return ids.includes(category.id) && category.url.includes('sports')
    })
    .map(({ id, name, url }) => ({ id, name, url }))
}

// sort variants based on the BC options config
export const sortVariants = (product) => {
  let variants = product.variants
  let options = product.options
  let optionLabels = {}
  let optionsOrder = []

  options.forEach((option, index) => {
    optionLabels[option.display_name] = []
    if (option.option_values) {
      option.option_values.forEach((optionValue) => {
        optionLabels[option.display_name].push(optionValue.label)
      })
    }
  })

  let optionLabelKeys = Object.keys(optionLabels)

  if (optionLabelKeys.length > 1) {
    optionLabels[optionLabelKeys[0]].forEach((option1) => {
      optionLabels[optionLabelKeys[1]].forEach((option2) => {
        optionsOrder.push({
          [optionLabelKeys[0]]: option1,
          [optionLabelKeys[1]]: option2,
        })
      })
    })

    let sortedVariants = optionsOrder
      .map((item) => {
        let optionKeys = Object.keys(item)
        let selectedVariant = null
        // find this combination
        variants.forEach((variant) => {
          let optionMatch = {
            [optionLabelKeys[0]]: false,
            [optionLabelKeys[1]]: false,
          }

          optionKeys.forEach((key) => {
            let option = variant.option_values.find((option) => option.option_display_name === key)

            if (!isEmpty(option) && option.label === item[key]) {
              optionMatch[key] = true
            }
          })

          if (optionMatch[optionLabelKeys[0]] && optionMatch[optionLabelKeys[1]]) {
            selectedVariant = variant
          }
        })

        if (!isEmpty(selectedVariant)) {
          return selectedVariant
        }
      })
      .filter((el) => el)

    return sortedVariants
  }

  return variants
}

// get the default option configuration
export const getDefaultProductOptions = (options) => {
  let optionLabels = {}
  let allOptionsLabels = []

  options.forEach((option) => {
    optionLabels[option.display_name] = null
    option.option_values.forEach((optionValue) => {
      if (optionValue.is_default) {
        optionLabels[option.display_name] = optionValue.label
      }
      if (
        option.display_name.toLowerCase() == 'color' &&
        !allOptionsLabels.includes(optionValue.label)
      ) {
        allOptionsLabels.push(optionValue.label)
      }
    })
  })

  return { optionLabels, allOptionsLabels }
}

// get the default option based on BC default option config
export const getDefaultVariant = (variants, defaultOptions, allOptions) => {
  let filteredDefaultOptions = { ...defaultOptions }
  let filteredKeys = Object.keys(filteredDefaultOptions)

  filteredKeys.forEach((key) => {
    if (filteredDefaultOptions[key] == null) {
      delete filteredDefaultOptions[key]
    }
  })

  filteredKeys = Object.keys(filteredDefaultOptions)

  if (filteredKeys.length == 0) {
    let variantFound = null
    for (let i = 0; i < allOptions.length; i++) {
      let color = allOptions[i]
      for (let j = 0; j < variants.length; j++) {
        let variant = variants[j]
        if (variant.inventory_level > 0 && !variant.purchasing_disabled) {
          let colorOptions = variant.option_values.find(
            (item) => item.option_display_name.toLowerCase() == 'color'
          )
          if (colorOptions) {
            if (color == colorOptions.label) {
              variantFound = variant
              break
            }
          }
        }
      }
      if (variantFound) {
        break
      }
    }
    if (variantFound) {
      return variantFound
    }
    return variants[0]
  }

  // all variants that match the first default option
  let possibleVariants = variants.filter((variant) => {
    let match = false

    for (let optionValue of variant.option_values) {
      if (
        optionValue.option_display_name === filteredKeys[0] &&
        optionValue.label === filteredDefaultOptions[filteredKeys[0]]
      ) {
        match = true
      }
    }
    if (match) return variant
  })

  if (possibleVariants.length == 0) {
    return variants[0]
  }

  // find the variant that matches the options
  let defaultVariant = possibleVariants.find((variant) => {
    let matches = 0
    filteredKeys.forEach((key) => {
      for (let optionValue of variant.option_values) {
        if (
          optionValue.option_display_name === key &&
          optionValue.label === filteredDefaultOptions[key]
        ) {
          matches++
          break
        }
      }
    })
    if (matches == filteredKeys.length) {
      return variant
    }
  })

  if (!isEmpty(defaultVariant)) return defaultVariant

  //return the next available variant
  return possibleVariants[0]
}

// get variant by the choices combination
export const getVariantByChoice = (choices, variants) => {
  let choicesKeys = Object.keys(choices)

  let variant = variants.find((variant) => {
    let matched = 0

    choicesKeys.forEach((key) => {
      variant.option_values.find((option) => {
        if (option.option_display_name == key && option.label == choices[key]) {
          matched++
        }
      })
    })

    if (choicesKeys.length == matched) {
      return variant
    }
  })

  return variant
}

// set choices based on the color and select option of the first variant
export const setFirstChoiceByColor = (color, variants) => {
  let variant = variants.find((variant) => {
    let option = variant.option_values.find((option) => {
      if (option.option_display_name == 'Color' && option.label == color) {
        return option
      }
    })

    if (!isEmpty(option)) {
      return variant
    }
  })

  if (!isEmpty(variant)) {
    let choices = {}
    variant.option_values.forEach((option) => {
      choices[option.option_display_name] = option.label
    })

    return choices
  }
  return null
}

// get the next available variant if the selected variant is not out of stock
export const getNextAvailableVariant = (label, variants) => {
  let variant = variants.find((variant) => {
    let option = variant.option_values.find((option) => option.label == label)

    if (!isEmpty(option) && variant.inventory_level > 0) {
      return variant
    }
  })

  return variant
}

export const getProductById = async (productId) => {
  try {
    const product = await getLambdaProductById(productId, {
      include: 'variants,custom_fields,primary_image,images',
      include_fields: `id,name,description,sku,price,retail_price,sale_price,categories,brand_id,inventorty_level,is_visible,condition,custom_url,is_visible,custom_fields`,
    })

    return product
  } catch (error) {
    console.log(error, 'error')
  }
}
function get15MinuteTimestamp() {
  const now = new Date()
  const msInMinute = 60000
  const msIn15Minutes = 5 * msInMinute

  // Get the current timestamp in milliseconds
  const currentTimestamp = now.getTime()

  // Round down the current timestamp to the nearest 5-minute interval
  const roundedTimestamp = Math.floor(currentTimestamp / msIn15Minutes) * msIn15Minutes

  return roundedTimestamp
}

export const readFromS3 = async (key, revalidate = false, useCheckoutLocale = false) => {
  const timestamp = get15MinuteTimestamp()
  let currentRegion = getCurrentRegion()
  let sha = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
  let stage = 'dev'
  if (process.env.NEXT_PUBLIC_ENVIRONMENT == 'staging') {
    stage = 'stage'
  } else if (process.env.NEXT_PUBLIC_ENVIRONMENT == 'production') {
    stage = 'prod'
  }
  let fileUrl = `https://cdn.gorewear.com/${stage}/${currentRegion.domain}/${key}${
    sha ? `?sha=${sha}&time=${timestamp}` : `?timestamp=${timestamp}`
  }`

  if (revalidate) {
    fileUrl = `https://gore-fe-public-assets.s3.amazonaws.com/${stage}/${currentRegion.domain}/${key}`
  }

  if (useCheckoutLocale) {
    let savedCountry = getCartLocale(currentRegion.domain)
    fileUrl = `https://cdn.gorewear.com/${stage}/${savedCountry.domain}/${key}${
      sha ? `?sha=${sha}&time=${timestamp}` : `?timestamp=${timestamp}`
    }`
  }

  if (revalidate && useCheckoutLocale) {
    let savedCountry = getCartLocale(currentRegion.domain)
    fileUrl = `https://gore-fe-public-assets.s3.amazonaws.com/${stage}/${savedCountry.domain}/${key}`
  }

  try {
    const data = await axios.get(fileUrl)
    if (data.data) {
      return data.data
    }
    return null
  } catch (error) {
    if (error.response && error.response.status) {
      console.error(`File not found in S3: ${error.response.status} - ${key}`)
      return null
    }
    console.error('Error reading from S3:', error.message)
    return null
  }
}

export const getProductBySku = async (
  sku,
  useS3Url = false,
  useCheckoutLocale = false,
  preventCache = false
) => {
  try {
    if (sku) {
      let currentTimestamp = new Date().getTime()
      let splitSkus = sku.split(',')
      let allProducts = []
      let allProdPromises = []
      for (let i = 0; i < splitSkus.length; i++) {
        allProdPromises.push(
          readFromS3(
            `product-${splitSkus[i]}.json${preventCache ? '?v=' + currentTimestamp : ''}`,
            useS3Url,
            useCheckoutLocale
          ).then((productData) => {
            if (productData) {
              allProducts.push(productData)
            }
          })
        )
      }
      await Promise.all(allProdPromises)
      if (allProducts.length == 1) {
        return allProducts[0]
      } else if (allProducts.length > 1) {
        return allProducts
      }
    }
  } catch (error) {
    console.error(`Error: File not found in S3: ${error}`)
  }
}

export const getImageFromSet = (image, imagesSet) => {
  if (imagesSet.length > 0) {
    return imagesSet.find((imageSet) => {
      let desc = JSON.parse(imageSet.description)

      return desc?.number == image?.number && desc?.colorCode == image?.colorCode
    })
  }

  return null
}

export const getImageByNumber = (number, isModel = false, images, colorCode) => {
  if (images.length > 0) {
    let imageFound = images.find((image) => {
      let desc = JSON.parse(image?.altText)

      if (desc.isModel) {
        return desc?.number == number && desc?.isModel == isModel && desc?.colorCode == colorCode
      }
    })

    if (imageFound) {
      return imageFound
    }

    return images.find((image) => {
      let desc = JSON.parse(image?.altText)

      if (desc.isModel) {
        return desc?.number == number && desc?.isModel == isModel
      }
    })
  }

  return null
}

export const getImageByWidth = (originalUrl, width) => {
  let urlPartials = originalUrl.split('original')

  if (urlPartials[1]) {
    return `${urlPartials[0]}${width}w${urlPartials[1]}`
  }

  return originalUrl
}

export const fetchCustomFields = (product) => {
  const customFields = []
  const objKeys = Object.keys(product)
  if (objKeys && objKeys.length > 0) {
    objKeys.map((key, index) => {
      if (key.startsWith('custom_')) {
        let title = key.split('custom_')[1]
        let value = product[key]
        let pushVal = {
          id: index,
          name: title,
          value: value,
        }
        customFields.push(pushVal)
      }
    })
  }
  return customFields
}

export const getLocalizedField = (fields, locale = 'en', keySuffix, key) => {
  let identifier = `${locale}_${keySuffix}`
  if (keySuffix != 'full_product_name') {
    identifier = keySuffix
  }

  let field = fields.find((item) => item[key] == identifier)

  if (field) return field

  let fallbackField = fields.find((item) => item[key] == keySuffix)

  return fallbackField ? fallbackField : null
}

export const getLocalizedMetaField = (fields, locale = 'en', keySuffix, key) => {
  let identifier = `${locale}_${keySuffix}`
  let field = fields.find((item) => item[key] == identifier)

  if (field) return field

  let fallbackField = fields.find((item) => item[key] == keySuffix)

  return fallbackField ? fallbackField : null
}

export const getLocalizedOptions = (product, locale, key) => {
  let option = getLocalizedMetaField(product.metafields, locale, key, 'key')

  if (!option) {
    return null
  }

  try {
    let parsedOption = JSON.parse(option.value)

    return parsedOption
  } catch (error) {
    return null
  }
}

export const localizeLabel = (localizedOptions, label) => {
  if (!localizedOptions) {
    return label
  }

  let newLabel = label

  Object.keys(localizedOptions).forEach((item) => {
    if (item.toLowerCase() == label.toLowerCase()) {
      newLabel = localizedOptions[item]
    }
  })

  return newLabel
}

export const localizeSizeLabel = (localizedOptions, label) => {
  let newCreatedSize = null
  if (label && label.includes('/')) {
    newCreatedSize = label.split('/')
    newCreatedSize = newCreatedSize[newCreatedSize.length - 1]
  }
  if (!localizedOptions) {
    return label
  }

  let newLabel = label

  Object.keys(localizedOptions).forEach((item) => {
    if (newCreatedSize && item.toLowerCase() == newCreatedSize.toLowerCase()) {
      newLabel = localizedOptions[item]
    } else if (item.toLowerCase() == label.toLowerCase()) {
      newLabel = localizedOptions[item]
    }
  })
  return newLabel
}

export const getFeaturesImages = (images) => {
  if (images.length > 0) {
    let featuredImages = images
      .map((image) => {
        let description

        if (image.node) {
          description = JSON.parse(image.node.altText)

          return {
            ...image.node,
            description,
            order: description.isFeatured || null,
          }
        } else {
          description = JSON.parse(image.altText)

          return {
            ...image,
            description,
            order: description.isFeatured || null,
          }
        }
      })
      .filter((image) => image.description.isFeatured)

    if (featuredImages) {
      let images = [...featuredImages]

      if (images.length > 3) {
        // group isFeatured by their color code
        let colorCodeGrouped = groupBy(images, (image) => image.description.colorCode)
        let featuredGroup = null

        for (let groupKey of Object.keys(colorCodeGrouped)) {
          if (colorCodeGrouped[groupKey] && colorCodeGrouped[groupKey].length == 3) {
            featuredGroup = colorCodeGrouped[groupKey]
            break
          }
        }

        // check if a color code contains 3 isFeatured images and use it
        if (featuredGroup && featuredGroup.length) {
          images = [...featuredGroup]
        } else {
          let uniqueFeaturedValues = [...new Map(images.map((item) => [item.order, item])).values()]

          images = [...uniqueFeaturedValues]
        }
      }

      let sortedFeaturedImages = images.sort(
        (a, b) => parseInt(a.description.isFeatured) - parseInt(b.description.isFeatured)
      )

      return sortedFeaturedImages?.map((image) => {
        return {
          urlOriginal: image?.urlOriginal,
        }
      })
    }
  }

  return []
}

export const getLocalizedProductSlug = (product, locale) => {
  let sku = product.sku
  let productName = getLocalizedMetaField(product.metafields, locale, 'full_product_name', 'key')

  if (productName) {
    let newProductName = productName.value.replace(/[{"+}]/g, '').toLowerCase()

    newProductName = newProductName.replace(/ä/g, 'ae')
    newProductName = newProductName.replace(/ö/g, 'oe')
    newProductName = newProductName.replace(/ü/g, 'ue')
    newProductName = newProductName.replace(/ß/g, 'ss')
    newProductName = newProductName.replace(/\//g, '-') // replace / with _
    newProductName = newProductName.replace(/√§/g, 'ae') // replace german symbol
    newProductName = newProductName.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // convert umlauts and accents to normal characters

    newProductName = newProductName.replace(/[^\w\s-]/gi, '') //replace non numeric characters

    newProductName = slugify(newProductName, { strict: true, remove: /[®™]/g })

    let slug = (newProductName + '-' + sku).toLowerCase().replace(/\//g, '')

    return slug
  }

  return product.slug
}

export const getMItem = (product, localePrefix) => {
  let dataMfield = product.filter(
    (item) =>
      item.key === `${localePrefix}_materials` ||
      item.key === `${localePrefix}_care_instructions` ||
      item.key === `${localePrefix}_product_details` ||
      item.key === `${localePrefix}_full_product_name` ||
      item.key === `${localePrefix}_designed_for` ||
      item.key === `${localePrefix}_why_we_made_this` ||
      item.key === `${localePrefix}_product_benefit_statement` ||
      item.key === `${localePrefix}_Feature_1` ||
      item.key === `${localePrefix}_Feature_2` ||
      item.key === `${localePrefix}_Feature_3`
  )

  return dataMfield
}

export const getDItem = (product) => {
  let dataDitem = product.filter(
    (item) =>
      item.key === `materials` ||
      item.key === `care_instructions` ||
      item.key === `product_details` ||
      item.key === `full_product_name` ||
      item.key === `designed_for` ||
      item.key === `why_we_made_this` ||
      item.key === `product_benefit_statement` ||
      item.key === `Feature_1` ||
      item.key === `Feature_2` ||
      item.key === `Feature_3`
  )

  return dataDitem
}

export const getEnItem = (product) => {
  let dataEnItem = product.filter(
    (item) =>
      item.key === `en_materials` ||
      item.key === `en_care_instructions` ||
      item.key === `en_product_details` ||
      item.key === `en_full_product_name` ||
      item.key === `en_designed_for` ||
      item.key === `en_why_we_made_this` ||
      item.key === `en_product_benefit_statement` ||
      item.key === `en_Feature_1` ||
      item.key === `en_Feature_2` ||
      item.key === `en_Feature_3`
  )

  return dataEnItem
}

export const getProductFeature = (selectMitem, selectEnItem, localePrefix) => {
  try {
    let features_td = []

    const productkey = ['Feature_1', 'Feature_2', 'Feature_3']

    productkey.map((feature) => {
      const selectedItem_feature =
        selectMitem.length > 0
          ? selectMitem?.find((item) => item.key === `${localePrefix}_${feature}`)
          : null

      if (selectedItem_feature !== undefined && selectedItem_feature !== null) {
        let replacedValue = selectedItem_feature?.value
          ? (selectedItem_feature?.value).replace('/', '')
          : ''

        let formatData = replacedValue ? JSON.parse(replacedValue) : ''

        features_td.push({ ...formatData })
      } else {
        let en_selectedItem =
          selectEnItem.length > 0 ? selectEnItem?.find((a) => a.key === `en_${feature}`) : null

        if (en_selectedItem !== undefined && en_selectedItem !== null) {
          let replacedValue = en_selectedItem?.value
            ? (en_selectedItem?.value).replace('/', '')
            : ''

          let formatData = replacedValue ? JSON.parse(replacedValue) : ''

          features_td.push({ ...formatData })
        }
      }
    })
    return features_td
  } catch (error) {
    console.error('getProductFeature', error?.message)
    console.log('Check product feature metafields')
    return []
  }
}

export const getLocalizedVariantPrices = (metafields, country, defaultPrices) => {
  if (metafields) {
    let isoCode = country.isoCode.toLowerCase()

    let price = defaultPrices.regularPrice
    let salePrice = defaultPrices.salePrice

    let localizedPrice = metafields?.find((item) => item.key == `${isoCode}_price`)
    let localizedSalePrice = metafields?.find((item) => item.key == `${isoCode}_sale_price`)

    if (localizedPrice) {
      let newPrice = localizedPrice.value.replace(/[{"+}]/g, '')

      price = parseFloat(newPrice)
    } else {
      price = country.storeSource == 'EU' ? null : price
    }

    if (localizedSalePrice) {
      let newSalePrice = localizedSalePrice.value.replace(/[{"+}]/g, '')

      salePrice = newSalePrice == 'null' ? null : parseFloat(newSalePrice)
    } else {
      salePrice = country.storeSource == 'EU' ? null : salePrice
    }

    return {
      regularPrice: price,
      salePrice: salePrice,
      calculatedPrice: salePrice || price,
    }
  }

  return defaultPrices
}

export const formatMetafieldValue = (value) => {
  if (value == null) {
    return value
  }

  if (isJsonString(value)) {
    // handles US metafield format
    let data = JSON.parse(value)
    let localeKey = Object.keys(data)[0]
    return data[localeKey]
  } else {
    // handles EU metafield format
    let data = value.replace(/[{"+}]/g, '')
    return data
  }
}

export const generateBreadcrumbs = (path) => {
  const segments = path.split('/').filter(Boolean)
  let currentUrl = ''
  return segments.map((segment) => {
    currentUrl += `/${segment}`
    return { name: segment.replaceAll('-', ' '), url: currentUrl }
  })
}

export const getLocalizedCategoryV2 = (ids, urlKey) => {
  let plpBreadCategory = getLocalizedCategoryV3(ids)
  if (plpBreadCategory.length > 1) {
    plpBreadCategory = plpBreadCategory[plpBreadCategory.length - 1]
  } else {
    plpBreadCategory = plpBreadCategory[0]
  }
  const categories = plpBreadCategory ? generateBreadcrumbs(plpBreadCategory[urlKey]) : []
  return categories
}

export const getLocalizedCategoryV3 = (ids) => {
  const keyPrefixes = {
    cycling: 'sports/cycling/',
    running: 'sports/running/',
  }
  const currentRegion = getCurrentRegion()
  const lang = currentRegion.localePrefix.toLowerCase()
  const categoryMappings = {
    accessories: ['gloves', 'headwear', 'overshoes', 'socks', 'warmers'],
    bottoms: ['bib-shorts', 'pants', 'shorts', 'tights', 'freedom-to-explore-collection'],
    tops: ['jackets', 'jerseys', 'shirts', 'vests', 'new-arrivals'],
    'base-layers': ['briefs-shorts', 'tops'],
  }

  const sportsCategories = categoriesData?.data?.filter((category) =>
    category?.url?.startsWith('sports')
  )

  const getCategoryByPrefixAndId = (prefix, id) => {
    for (const category of sportsCategories) {
      if (category.id === id && category.url.includes(prefix)) {
        return category
      }
    }
    return null
  }

  const localizedCategories = []

  for (const id of ids) {
    for (const prefix of Object.values(keyPrefixes)) {
      for (const categoryType of Object.keys(categoryMappings)) {
        const category = getCategoryByPrefixAndId(`${prefix}${categoryType}`, id)
        if (category && category[`${lang}_url`] !== '') {
          localizedCategories.push(category)
          break // Stop searching once a match is found
        }
      }
    }
  }

  return localizedCategories
}

export const getAppkey = (domain) => {
  let region

  region = domain ? getRegion(domain) : getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  const counrtyLang = region.localePrefix.toUpperCase()

  let appKey

  if (counrtyLang === 'ES') {
    appKey = process.env.NEXT_PUBLIC_YOTPO_ES_APP_KEY
  } else if (counrtyLang === 'FR') {
    appKey = process.env.NEXT_PUBLIC_YOTPO_FR_APP_KEY
  } else if (counrtyLang === 'IT') {
    appKey = process.env.NEXT_PUBLIC_YOTPO_IT_APP_KEY
  } else if (counrtyLang === 'DE') {
    appKey = process.env.NEXT_PUBLIC_YOTPO_DE_APP_KEY
  } else if (counrtyLang === 'EN' && region.region === 'EU') {
    appKey = process.env.NEXT_PUBLIC_YOTPO_APP_KEY
  } else {
    appKey = process.env.NEXT_PUBLIC_YOTPO_EN_APP_KEY
  }
  return appKey
}

export const getCategorySlugs = (category) => {
  const slugs = {
    'en-US': category['url'],
    'en-GB': category['en_url'],
    es: category['es_url'] != '' ? category['es_url'] : null,
    fr: category['fr_url'] != '' ? category['fr_url'] : null,
    it: category['it_url'] != '' ? category['it_url'] : null,
    de: category['de_url'] != '' ? category['de_url'] : null,
  }
  return slugs
}

export const getLocaleSecretKey = (domain) => {
  let region

  region = domain ? getRegion(domain) : getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  const counrtyLang = region.localePrefix.toUpperCase()

  let secretKey

  if (counrtyLang === 'ES') {
    secretKey = process.env.NEXT_PUBLIC_YOTPO_ES_SECRET_KEY
  } else if (counrtyLang === 'FR') {
    secretKey = process.env.NEXT_PUBLIC_YOTPO_FR_SECRET_KEY
  } else if (counrtyLang === 'IT') {
    secretKey = process.env.NEXT_PUBLIC_YOTPO_IT_SECRET_KEY
  } else if (counrtyLang === 'DE') {
    secretKey = process.env.NEXT_PUBLIC_YOTPO_DE_SECRET_KEY
  } else if (counrtyLang === 'EN' && region.region === 'EU') {
    secretKey = process.env.NEXT_PUBLIC_YOTPO_SECRET_KEY
  } else {
    secretKey = process.env.NEXT_PUBLIC_YOTPO_EN_SECRET_KEY
  }

  return secretKey
}

export const productName = (name = '') => {
  try {
    return name.replace('√†', 'à')
  } catch (error) {
    return name
  }
}

export const getOfferPrice = (product) => {
  try {
    let lowestPrice = 0
    let regularPrice = 0
    let variantRegularPrices = []
    let variantSalePrices = []

    let availableVariants = product.variants.filter((variant) => !variant.purchasing_disabled)

    availableVariants.forEach((variant) => {
      if (variant.sale_price && !variantSalePrices.includes(variant.sale_price)) {
        variantSalePrices.push(variant.sale_price)
      }

      if (variant.price && !variantRegularPrices.includes(variant.price)) {
        variantRegularPrices.push(variant.price)
      }
    })

    lowestPrice = variantSalePrices.length > 0 && Math.min(...variantSalePrices)
    regularPrice = variantRegularPrices.length > 0 && Math.max(...variantRegularPrices)

    return {
      salePrice: lowestPrice || null,
      regularPrice: regularPrice || null,
    }
  } catch (error) {
    return null
  }
}

export const getActivityLabel = (customFields) => {
  const region = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)

  const gender = getLocalizedField(customFields, region.localePrefix, 'gender', 'name')
  const sportActivity = getLocalizedField(
    customFields,
    region.localePrefix,
    'sport_activity',
    'name'
  )

  return {
    gender: gender ? gender.value : null,
    sportActivity: sportActivity ? sportActivity.value : null,
  }
}

export const getGroupNameInfo = (groupName) => {
  if (groupName) {
    const groupNamePartials = groupName.split('|')

    return {
      name: groupNamePartials[0]?.trim(),
      fullName: groupName,
      domain: groupNamePartials[1] ? groupNamePartials[1].trim().toLowerCase() : null,
    }
  }

  return null
}

export const allowGroupDiscount = (groupNameInfo) => {
  if (groupNameInfo) {
    let region = getCurrentRegion()
    let cartRegion = getCartLocale(region.domain)

    if (region.storeSource == 'EU' && groupNameInfo.domain) {
      // if groupNameInfo has domain then check the locale if it matches the shipping domain and site domain
      if (groupNameInfo.domain != region.domain || groupNameInfo.domain != cartRegion.domain) {
        return false
      }
    }

    return true
  }

  return false
}

export const getHoverImage = (images, color) => {
  if (images) {
    const validImages = images
      .filter((image) => {
        if (isJsonString(image.description)) {
          return image
        }
      })
      .map((image) => {
        let description = JSON.parse(image.description)

        return {
          ...image,
          ...description,
        }
      })

    let selectedColorImages = validImages.filter(
      (image) => image?.colorName?.toLowerCase() == color.toLowerCase()
    )

    if (selectedColorImages && selectedColorImages.length) {
      let hoverImage = selectedColorImages.find((image) => image?.hover && image.number == 'oh1')

      if (hoverImage) {
        return hoverImage
      }

      let alternateHoverImage = selectedColorImages.find((image) => {
        return !image.isModel && image.number == '2'
      })

      if (alternateHoverImage) {
        return alternateHoverImage
      }

      let fallbackImage = selectedColorImages.find((image) => !image.isModel && image.number == '1')

      // return standard image 1
      if (fallbackImage) {
        return fallbackImage
      }
    }

    // return last fallback
    return images[0]
  }

  return null
}

const getRegionProductName = (metafields, region) => {
  const language = region?.localePrefix || 'en'
  const productNameMetafield = metafields.find(
    (item) => item.key == `${language}_full_product_name`
  )
  const productName = productNameMetafield?.value.replace(/[{"+}]/g, '') || ''
  return productName
}

const regionLocalizedPrices = (variants, region) => {
  let availableVariants = variants.filter((variant) => !variant.purchasing_disabled)
  let newVariants = []

  if (availableVariants?.length > 0 && region.storeSource == 'EU') {
    for (let variant of availableVariants) {
      try {
        let metafields = variant.metafields

        let newPrice = getLocalizedVariantPrices(metafields, region, {
          regularPrice: variant.price,
          salePrice: variant.sale_price,
          calculatedPrice: variant.calculated_price,
        })

        newVariants.push({
          ...variant,
          price: newPrice?.regularPrice,
          sale_price: newPrice?.salePrice,
          calculated_price: newPrice?.calculatedPrice,
        })
      } catch (error) {
        newVariants.push(variant)
      }
    }

    return newVariants
  }

  return variants
}

const prepareProductFeatureData = (product, region) => {
  const featureData = {}
  const imagesArray = product?.imagesArray?.edges || product?.imagesArray
  featureData.images = getFeaturesImages(imagesArray)
  featureData.designedFor = product?.designedFor
  featureData.whyWeMadeThis = product?.whyWeMadeThis
  featureData.ctaQuote = product?.productModules?.additionalData?.ctaQuote || null
  featureData.ctaQuoteButtonText =
    product?.productModules?.additionalData?.ctaQuoteButtonText || null
  featureData.ctaQuoteUrl = product?.productModules?.additionalData?.ctaQuoteUrl || null
  featureData.ctaQuoteReviewer = product?.productModules?.additionalData?.ctaQuoteReviewer || null
  featureData.ctaQuoteDate = product?.productModules?.additionalData?.ctaQuoteDate || null
  featureData.textContent = product?.productFeature
  featureData.weight = product?.weight || 0

  const specsData = []
  const specsKey = [
    'care_instructions',
    'materials',
    'product_details',
    'full_product_name',
    'product_benefit_statement',
  ]

  specsKey.forEach((item) => {
    let selectedSpec = null
    let specData = ''
    let formatedData = null

    if (region?.localePrefix !== 'en') {
      selectedSpec = product?.metafields?.find(
        (feature) => feature?.key == `${region.localePrefix}_${item}`
      )
      selectedSpec =
        selectedSpec || product?.metafields?.find((feature) => feature?.key == `en_${item}`)

      specData = selectedSpec?.value ? (selectedSpec?.value).replace(/[{"+}]/g, '') : ''
      formatedData = specData
    } else {
      selectedSpec = product?.metafields?.find((feature) => feature?.key == item)

      if (selectedSpec) {
        specData = selectedSpec?.value
          ? (selectedSpec?.value).substring(selectedSpec.value.indexOf(':') + 1)
          : ''
        formatedData = specData.substring(0, specData.indexOf('}'))
        formatedData = formatedData.replace('"', '')
        formatedData = formatedData.substring(0, formatedData.indexOf('"'))
      } else {
        selectedSpec =
          selectedSpec || product?.metafields?.find((feature) => feature?.key == `en_${item}`)
        specData = selectedSpec?.value ? (selectedSpec?.value).replace(/[{"+}]/g, '') : ''
        formatedData = specData
      }
    }

    if (selectedSpec) {
      if (formatedData.includes('\\n')) {
        let items = formatedData.split('\\n')
        items?.map((i) => {
          specsData.push({ [item]: i })
        })
      } else {
        specsData.push({ [item]: formatedData })
      }
    }
  })

  featureData.specs = specsData

  const requiredCustomFieldsKey = ['seat_insert', 'highlights', 'backpack_use', 'secondary_end_use']

  featureData.custom_fields = product?.custom_fields?.filter((field) => {
    return requiredCustomFieldsKey?.includes(field?.name)
  })

  return featureData
}

export const groupColorsAndSizes = (variants) => {
  const restructuredVariants = []
  variants.map((variant) => {
    const colorLabel = variant.option_values.find((opt) => opt.option_display_name == 'Color').label
    const sizeLabel = variant.option_values.find((opt) => opt.option_display_name == 'Size').label
    restructuredVariants.push({
      color: colorLabel,
      size: sizeLabel,
      inventory: variant.inventory_level,
      disabled: variant.purchasing_disabled,
    })
  })

  if (restructuredVariants.length > 0) {
    const data = restructuredVariants
    const groupedColorsInventoryZero = data
      .filter((item) => item.inventory === 0 && item.disabled === false)
      .reduce((result, item) => {
        result[item.color] = result[item.color] || []
        result[item.color].push(item.size)
        return result
      }, {})

    const groupedColorsAvailableSizes = data
      .filter((item) => item.disabled === false)
      .reduce((result, item) => {
        result[item.color] = result[item.color] || []
        result[item.color].push(item.size)
        return result
      }, {})
    const groupedColorsDisabledTrue = data
      .filter((item) => item.disabled === true)
      .reduce((result, item) => {
        result[item.color] = result[item.color] || []
        result[item.color].push(item.size)
        return result
      }, {})
    return {
      groupedColorsInventoryZero: groupedColorsInventoryZero,
      groupedColorsDisabledTrue: groupedColorsDisabledTrue,
      groupedColorsAvailableSizes: groupedColorsAvailableSizes,
    }
  }
}

const variantData = (variants) => {
  let variantArray = new Array()
  variants.map((variant) => {
    let purchasing_disabled = variant.purchasing_disabled
    let v_entityId = variant.id
    let inventory_level = variant.inventory_level
    let options = variant.option_values
    let optArr = options.map((opt) => {
      let opt_name = opt.option_display_name
      let opt_label = opt.label
      return {
        name: opt_name,
        label: opt_label,
      }
    })
    variantArray.push({
      entityId: v_entityId,
      option: optArr,
      inventory_level: inventory_level,
      purchasing_disabled: purchasing_disabled,
    })
  })
  return variantArray
}

const getVariantId = (variants, defaultChoices) => {
  let varnt = variantData(variants)
  let colorValue = defaultChoices.Color
  let sizeValue = defaultChoices.Size
  let variant_id = null
  for (let variant in varnt) {
    let options = varnt[variant].option
    let optionsLength = Object.keys(options).length
    if (optionsLength == 2) {
      if (
        options[0].name == 'Size' &&
        options[0].label == sizeValue &&
        options[1].name == 'Color' &&
        options[1].label == colorValue
      ) {
        variant_id = varnt[variant].entityId
      } else if (
        options[0].name == 'Color' &&
        options[0].label == colorValue &&
        options[1].name == 'Size' &&
        options[1].label == sizeValue
      ) {
        variant_id = varnt[variant].entityId
      }
    }
  }
  return variant_id
}

export const getNormalisedProduct = (product, region) => {
  const normalisedProduct = {}
  normalisedProduct.__typename = 'Product'
  normalisedProduct.id = product.id
  normalisedProduct.availability = product.availability || 'available'
  normalisedProduct.name =
    region.domain == 'en-us' ? product.name : getRegionProductName(product.metafields, region)
  normalisedProduct.defaultName = product?.name
  normalisedProduct.sku = product.sku
  normalisedProduct.description = product.description
  normalisedProduct.images = flattenGraphqlArray(product.imagesArray)
  const processedCustomFields = product.custom_fields.map((item) => {
    delete item.id
    return item
  })
  normalisedProduct.shippingGroup = ''
  console.log(product.metafields)
  if(product.metafields && product.metafields.length > 0) {
    let shippingGrp = product.metafields.find(item => item.key == 'shipping-groups')
    if(shippingGrp) {
      normalisedProduct.shippingGroup = shippingGrp.value
    }
  }
  normalisedProduct.custom_fields = processedCustomFields
  normalisedProduct.slugs = {
    en: getLocalizedProductSlug(product, 'en'),
    es: getLocalizedProductSlug(product, 'es'),
    de: getLocalizedProductSlug(product, 'de'),
    it: getLocalizedProductSlug(product, 'it'),
    fr: getLocalizedProductSlug(product, 'fr'),
  }
  const localisedSizes = {}
  product.variants.map((item) => {
    let sizeLabel = item.option_values.find(
      (option) => option.option_display_name.toLowerCase() == 'size'
    )?.label
    localisedSizes[sizeLabel] = sizeLabel
    if (item.metafields.length > 0) {
      let metaSizeLabel = item.metafields.find((option) => option.key.endsWith('_size'))?.value
      if (metaSizeLabel) {
        localisedSizes[sizeLabel] = metaSizeLabel.replace(/[{""}]/g, '')
      }
    }
  })
  normalisedProduct.localisedSizes = localisedSizes
  const newVariants = regionLocalizedPrices(product.variants, region)
  normalisedProduct.offerPrice = getOfferPrice({ variants: newVariants })
  let pricesOverride = {
    price: {
      value: product?.prices?.price?.value,
      currencyCode: region?.isoCode,
    },
    salePrice: null,
    retailPrice: null,
  }

  if (normalisedProduct?.offerPrice != null) {
    pricesOverride.basePrice = {
      value: normalisedProduct?.offerPrice?.regularPrice || null,
      currencyCode: region?.isoCode,
    }

    if (normalisedProduct?.offerPrice?.salePrice) {
      pricesOverride.salePrice = {
        value: normalisedProduct?.offerPrice?.salePrice || null,
        currencyCode: region?.isoCode,
      }
      pricesOverride.price = {
        value: normalisedProduct?.offerPrice?.salePrice || null,
        currencyCode: region?.isoCode,
      }
    } else {
      pricesOverride.price = {
        value: normalisedProduct?.offerPrice?.regularPrice || null,
        currencyCode: region?.isoCode,
      }
    }
  }

  normalisedProduct.prices = pricesOverride
  normalisedProduct.price = normalisedProduct?.offerPrice?.regularPrice || null
  normalisedProduct.sale_price = normalisedProduct?.offerPrice?.salePrice || null
  normalisedProduct.calculated_price =
    normalisedProduct?.offerPrice?.salePrice || normalisedProduct?.offerPrice?.regularPrice
  normalisedProduct.categories = product.categories

  normalisedProduct.modules = product.productModules
  normalisedProduct.reviews = product.productReviews
  normalisedProduct.featureComponent = prepareProductFeatureData(product, region)
  normalisedProduct.fabricTechnology = product.fabricTechnology || null
  normalisedProduct.selectedVariant = product.selectedVariant
  normalisedProduct.defaultChoices = product.defaultChoices
  normalisedProduct.options = product.options
  normalisedProduct.sizes = product.sizes
  normalisedProduct.colors = product.colors
  let sortedVariants = sortVariants(product)
  sortedVariants = sortedVariants.map((item) => {
    return {
      ...item,
      calculated_price:
        item.sale_price != null && item.sale_price != 0 ? item.sale_price : item.price,
    }
  })
  normalisedProduct.variants = sortedVariants
  normalisedProduct.sizeAvailability = groupColorsAndSizes(sortedVariants)

  normalisedProduct.modifiers = product.modifiers
  normalisedProduct.benefitStatement = product?.benefitStatement || ''
  normalisedProduct.whyWeMadeThis = product?.whyWeMadeThis || ''

  // Price Update
  let selectedVariantId = getVariantId(normalisedProduct.variants, normalisedProduct.defaultChoices)
  let variantMetafields = normalisedProduct.variants.find(
    (variant) => variant.id == selectedVariantId
  ).metafields

  let newPrice = getLocalizedVariantPrices(variantMetafields, region, normalisedProduct.prices)

  let selectedVariant = normalisedProduct.variants.find(
    (variant) => variant?.id == selectedVariantId
  )
  if (typeof selectedVariant !== 'undefined') {
    normalisedProduct.selectedVariant = selectedVariant.sku
    let newProductPrice = {
      regularPrice: null,
      salePrice: null,
      calculatedPrice: null,
    }
    if (region.domain == 'en-us') {
      newProductPrice = {
        regularPrice: selectedVariant.price,
        salePrice: selectedVariant.sale_price != null ? selectedVariant.sale_price : null,
        calculatedPrice:
          selectedVariant.sale_price != null && selectedVariant.sale_price != 0
            ? selectedVariant.sale_price
            : selectedVariant.price,
      }
    } else {
      newProductPrice = {
        regularPrice: newPrice?.regularPrice,
        salePrice: newPrice?.salePrice !== null ? newPrice?.salePrice : null,
        calculatedPrice:
          newPrice?.salePrice !== null ? newPrice?.salePrice : newPrice?.regularPrice,
      }
    }
    normalisedProduct.prices = newProductPrice
    normalisedProduct.price = newProductPrice.regularPrice
    normalisedProduct.sale_price = newProductPrice.salePrice
    normalisedProduct.calculated_price = newProductPrice.calculatedPrice
  }

  return normalisedProduct
}

export const getProductReviewCount = (productId) => {
  const productReview = productReviews.find((item) => item.id == productId)
  return productReview ? productReview : {}
}

export const getAllCachedProducts = () => {
  const productData = cachedProductSlugs
  const productSlugs = []
  for (let slugs in productData) {
    productSlugs.push({
      slug: productData[slugs].slug,
    })
  }
  return productSlugs
}

const inStockColors = (variants) => {
  let colorList = []
  variants.forEach((variant) => {
    if (variant.inventory_level > 0) {
      let color = variant.option_values.find((opt) => opt.option_display_name == 'Color').label
      if (!colorList.includes(color)) {
        colorList.push(color)
      }
    }
  })
  return colorList
}

const sortBySalePrice = (variants) => {
  return variants.sort((a, b) => {
    const salePriceA = a.sale_price !== 0 ? a.sale_price : a.price
    const salePriceB = b.sale_price !== 0 ? b.sale_price : b.price
    return salePriceA - salePriceB
  })
}

const groupByColor = (variants, sizes) => {
  const grouped = {}
  variants.forEach((obj) => {
    if (!grouped[obj.color]) {
      grouped[obj.color] = []
    }
    delete obj.metafields
    grouped[obj.color].push(obj)
  })
  // Sort objects within each color group based on the sequence of sizes
  for (const color in grouped) {
    if (grouped.hasOwnProperty(color)) {
      grouped[color].sort((a, b) => {
        return sizes.indexOf(a.size) - sizes.indexOf(b.size)
      })
    }
  }
  return grouped
}

export const prepareCollectionData = async (data, category = null) => {
  let region = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  let returnCollectionData = []
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let item = data[i]
      if (item?.sku || item?.attributes?.sku) {
        const bcProductData = await getProductBySku(item.sku || item.attributes.sku)
        if (
          bcProductData &&
          bcProductData.images.length > 0 &&
          bcProductData.variants.length > 0 &&
          bcProductData.options.length > 0
        ) {
          bcProductData.variants = bcProductData.variants.filter(
            (item) => !item.purchasing_disabled
          )
          for (let item = 0; item < bcProductData.variants.length; item++) {
            let variant = bcProductData.variants[item]
            let localePrice = variant.price != null ? parseFloat(variant.price) : 0
            let localeSalePrice = variant.sale_price != null ? parseFloat(variant.sale_price) : 0
            if (region.domain != 'en-us') {
              localePrice = variant.metafields
                .find((meta) => meta.key == `${region.isoCode.toLowerCase()}_price`)
                ?.value?.replace(/[{""}]/g, '')
              localePrice = localePrice && localePrice != 'null' ? parseFloat(localePrice) : 0
              localeSalePrice = variant.metafields
                .find((meta) => meta.key == `${region.isoCode.toLowerCase()}_sale_price`)
                ?.value?.replace(/[{""}]/g, '')
              localeSalePrice =
                localeSalePrice && localeSalePrice != 'null' ? parseFloat(localeSalePrice) : 0
            }
            bcProductData.variants[item]['price'] = localePrice
            bcProductData.variants[item]['sale_price'] = localeSalePrice
          }
          let variantColors = inStockColors(bcProductData.variants)
          let colorSequence = bcProductData.options.find(
            (opt) => opt.display_name == 'Color'
          ).option_values
          let availableColors = colorSequence.filter((obj) => variantColors.includes(obj.label))
          availableColors.sort((a, b) => {
            if (a.is_default && !b.is_default) {
              return -1
            } else if (!a.is_default && b.is_default) {
              return 1
            } else {
              return a.sort_order - b.sort_order
            }
          })

          let variantsSorted = sortBySalePrice(bcProductData.variants)

          for (let i = 0; i < variantsSorted.length; i++) {
            let colorCode = variantsSorted[i]['sku'].split('-')[1]
            variantsSorted[i]['colorCode'] = colorCode
            variantsSorted[i]['color'] = variantsSorted[i].option_values.find(
              (opt) => opt.option_display_name == 'Color'
            ).label
            variantsSorted[i]['size'] = variantsSorted[i].option_values.find(
              (opt) => opt.option_display_name == 'Size'
            ).label
            let hoverImage =
              getHoverImage(bcProductData?.images, variantsSorted[i]['color']) || null
            variantsSorted[i]['hoverImage'] = hoverImage?.url_standard || null
            if (variantsSorted[i].metafields.length > 0) {
              variantsSorted[i]['sizeTrans'] =
                variantsSorted[i].metafields
                  .find((meta) => meta.key == `${getSizeLocaleKey()}_size`)
                  ?.value?.replace(/[{""}]/g, '') || variantsSorted[i]['size']
            }
          }
          let availableSizes = bcProductData.options.find(
            (opt) => opt.display_name == 'Size'
          ).option_values
          availableSizes.sort((a, b) => {
            return a.sort_order - b.sort_order
          })
          let sortedSizes = availableSizes.map((size) => size.label)
          let groupedVariants = groupByColor(variantsSorted, sortedSizes)

          let finalColorSorting = []
          if (category && category['sort_rules'] != '') {
            let sortRule = JSON.parse(category['sort_rules'])
            let firstSortRule = sortRule ? Object.keys(sortRule)[0] : null
            if (
              firstSortRule &&
              firstSortRule == 'colororder' &&
              sortRule.colororder.split('|').length > 0
            ) {
              const sortOrder = sortRule['colororder'].split('|')
              variantsSorted = variantsSorted.sort((a, b) => {
                let indexA = sortOrder.indexOf(a.colorCode)
                let indexB = sortOrder.indexOf(b.colorCode)
                if (indexA === -1 && indexB === -1) {
                  return 0
                } else if (indexA === -1) {
                  return 1
                } else if (indexB === -1) {
                  return -1
                }
                return indexA - indexB
              })
            }
            variantsSorted.map((item) => {
              let color = item.option_values.find((opt) => opt.option_display_name == 'Color').label
              if (!finalColorSorting.includes(color) && item.inventory_level > 0) {
                finalColorSorting.push(color)
              }
            })
          } else {
            finalColorSorting = availableColors.map((item) => item.label)
          }
          if (finalColorSorting.length == 0) {
            let onlyPurchasableColors = Object.keys(groupedVariants)
            finalColorSorting = colorSequence
              .map((item) => {
                if (onlyPurchasableColors.includes(item.label)) {
                  return item.label
                }
              })
              .filter((item) => item)
          }

          let defaultVariant = {
            color: null,
            size: null,
            vId: null,
            price: null,
            salePrice: null,
            imageUrl: null,
            hoverImage: null,
          }
          if (finalColorSorting.length > 0) {
            defaultVariant.color = finalColorSorting[0]
            // check if default size selected in BC
            let defaultSize = bcProductData.options
              .find((opt) => opt.display_name == 'Size')
              .option_values.find((opt) => opt.is_default == true)
            if (defaultSize) {
              defaultVariant.size = defaultSize.label
              let selectedVariant = variantsSorted.find(
                (variant) =>
                  variant.color.toLowerCase() == defaultVariant.color.toLowerCase() &&
                  variant.size.toLowerCase() == defaultVariant.size.toLowerCase()
              )
              if (selectedVariant && selectedVariant.inventory_level > 0) {
                defaultVariant.vId = selectedVariant.id
                defaultVariant.price = selectedVariant.price
                defaultVariant.salePrice = selectedVariant.sale_price
                defaultVariant.imageUrl = selectedVariant.image_url
                defaultVariant.hoverImage = selectedVariant.hoverImage
              }
            } else {
              let availableVariant = groupedVariants[defaultVariant.color].find(
                (variant) => variant.inventory_level > 0
              )
              if (availableVariant) {
                defaultVariant.size = availableVariant.size
                defaultVariant.vId = availableVariant.id
                defaultVariant.price = availableVariant.price
                defaultVariant.salePrice = availableVariant.sale_price
                defaultVariant.imageUrl = availableVariant.image_url
                defaultVariant.hoverImage = availableVariant.hoverImage
              } else {
                defaultVariant.size = groupedVariants[defaultVariant.color][0].size
                defaultVariant.vId = groupedVariants[defaultVariant.color][0].id
                defaultVariant.price = groupedVariants[defaultVariant.color][0].price
                defaultVariant.salePrice = groupedVariants[defaultVariant.color][0].sale_price
                defaultVariant.imageUrl = groupedVariants[defaultVariant.color][0].image_url
                defaultVariant.hoverImage = groupedVariants[defaultVariant.color][0].hoverImage
              }
            }
          }

          let meta_benefit_statement = ''
          let product_details = ''
          let product_name = ''
          if (region.domain == 'en-us') {
            meta_benefit_statement = bcProductData.metafields.find(
              (meta) =>
                meta.key == 'en_product_benefit_statement' ||
                meta.key == 'product_benefit_statement'
            )?.value
            meta_benefit_statement =
              meta_benefit_statement && isJsonString(meta_benefit_statement)
                ? JSON.parse(meta_benefit_statement)?.en
                : ''
            product_details = bcProductData.metafields.find(
              (meta) => meta.key == 'en_product_details' || meta.key == 'product_details'
            )?.value
            product_details =
              product_details && isJsonString(product_details)
                ? JSON.parse(product_details)?.en
                : ''
            product_name = bcProductData.title
          } else {
            meta_benefit_statement = bcProductData.metafields.find(
              (meta) => meta.key == `${region.localePrefix}_product_benefit_statement`
            )?.value
            meta_benefit_statement = meta_benefit_statement
              ? meta_benefit_statement.replace(/[{""}]/g, '')
              : ''
            product_details = bcProductData.metafields.find(
              (meta) => meta.key == `${region.localePrefix}_product_details`
            )?.value
            product_details = product_details ? product_details.replace(/[{""}]/g, '') : ''
            product_name = bcProductData.title
          }
          let categoryName = null
          let catName = ''
          if (item.categories || item?.attributes?.categories) {
            let categoriesAttribute = item.categories || item.attributes.categories
            if (categoriesAttribute.includes('Multi Sport')) {
              catName = 'Multi Sport'
            } else {
              for (let cat in categoriesAttribute) {
                let val = categoriesAttribute[cat].includes('&amp;')
                  ? categoriesAttribute[cat].replace('&amp;', '&')
                  : categoriesAttribute[cat]
                let arr = ['Cycling', 'Running', 'Ski']
                if (arr.includes(val)) {
                  if (catName == '') {
                    catName = val
                    break
                  } else {
                    catName = catName + ' ' + val
                    break
                  }
                }
              }
              if (categoriesAttribute.includes('Men') && categoriesAttribute.includes('Women')) {
                catName = catName
              } else if (categoriesAttribute.includes('Men')) {
                catName = catName + ' . Men'
              } else if (categoriesAttribute.includes('Women')) {
                catName = catName + ' . Women'
              }
            }
          }
          categoryName = catName
          const gender_custom_field =
            bcProductData.custom_fields.find(
              (item) => item.name == 'en_gender' || item.name == 'gender'
            )?.value || ''
          const sport_activity_custom_field =
            bcProductData.custom_fields.find(
              (item) => item.name == 'en_sport_activity' || item.name == 'sport_activity'
            )?.value || ''
          const is_coming_soon_custom_field =
            bcProductData.custom_fields.find((item) => item.name == 'is_coming_soon')?.value || ''
          const is_new_custom_field =
            bcProductData.custom_fields.find((item) => item.name == 'is_new')?.value || ''
          const fabric_tech_custom_field =
            bcProductData.custom_fields.find((item) => item.name == 'fabric_tech')?.value || ''
          const new_colours_custom_field =
            bcProductData.custom_fields.find((item) => item.name == 'new_colours')?.value || ''
          const thumbnailImg = bcProductData.images.find((img) => img.is_thumbnail)?.url_thumbnail
          const tempProductData = {
            id: bcProductData.id,
            description: meta_benefit_statement,
            product_details: product_details,
            is_coming_soon: is_coming_soon_custom_field,
            is_new: is_new_custom_field,
            new_colours: new_colours_custom_field,
            fabric_tech: fabric_tech_custom_field,
            categoriesId: bcProductData.categories || [],
            name: product_name,
            category: categoryName,
            weight: bcProductData.weight || 0,
            selectedVariant: defaultVariant,
            sku: bcProductData.sku,
            intellisuggestData: item?.intellisuggestData || null,
            intellisuggestSignature: item?.intellisuggestSignature || null,
            slug: bcProductData.defaultSlug,
            options: bcProductData?.options || null,
            // allVariantImages: bcProductData.images,
            custom_fields: bcProductData.custom_fields,
            reviewsCount: getProductReviewCount(bcProductData.id),
            gender: gender_custom_field,
            sport_activity: sport_activity_custom_field,
            imageRecUrl: thumbnailImg || null,
            groupedVariants: groupedVariants,
            availableColors: finalColorSorting,
            enProductName: bcProductData.name,
          }
          returnCollectionData.push(tempProductData)
        }
      }
    }
  }
  return returnCollectionData
}

export const getSizeLocaleKey = () => {
  let region = getCurrentRegion()
  let lang = 'eu'
  if (region.domain.includes('fr')) {
    lang = 'fr'
  } else if (region.domain.includes('uk')) {
    lang = 'uk'
  } else if (region.domain.includes('it')) {
    lang = 'it'
  } else if (region.domain.includes('ie')) {
    lang = 'ie'
  }
  return lang
}

export const getCategoryDataByPath = (path, key = 'url') => {
  const category = categoriesData.data.find((item) => item[key] == path)
  return category
}

export const getCategoryById = (id) => {
  const category = categoriesData.data.find((item) => item.id == id)
  return category
}

export const prepareCartForCheckout = async (cartData) => {
  try {
    let currentReg = getCurrentRegion()
    let checkoutLocale = getCartLocale(currentReg.domain)
    let copyCart = cloneDeep(cartData)
    let cartItems = copyCart?.all_cart_items || []
    if (cartItems.length == 0) {
      cartItems = cartData?.line_items?.physical_items || []
    }
    const cartDiscounts = await getCartDiscounts(cartData, ['name', 'redemption_type'])
    const coupons = cartDiscounts ? prepareCouponDataGTM([...cartDiscounts]) : null

    const finalItemList = []
    if (cartItems.length > 0) {
      // let cartItems = copyCart?.all_cart_items
      for (let i = 0; i < cartItems.length; i++) {
        let productReview = getProductReviewCount(cartItems[i].product_id)
        let productData = await getProductBySku(cartItems[i].sku.split('-')[0], false, true)

        let gender =
          productData.custom_fields.find((field) => {
            return field.name === 'gender'
          })?.en_trans || ''
        let sport =
          productData.custom_fields.find((field) => {
            return field.name === 'sport_activity'
          })?.en_trans || ''
        let category_1 = productData.categories[2]
          ? getCategoryById(productData.categories[2])?.name
          : ''
        let category_2 = productData.categories[3]
          ? getCategoryById(productData.categories[3])?.name
          : ''
        let salePrice =
          cartItems[i].variant.sale_price != null
            ? cartItems[i].variant.sale_price
            : cartItems[i].variant.price
        let price = cartItems[i].variant.price
        if (checkoutLocale.domain != 'en-us') {
          const addedVariant =
            productData.variants.find((item) => item.id == cartItems[i].variant.id)?.metafields ||
            null
          if (addedVariant) {
            salePrice = addedVariant
              .find((varnt) => varnt.key == `${checkoutLocale.isoCode.toLowerCase()}_sale_price`)
              ?.value?.replace(/[{""}]/g, '')
            price = addedVariant
              .find((varnt) => varnt.key == `${checkoutLocale.isoCode.toLowerCase()}_price`)
              ?.value?.replace(/[{""}]/g, '')
            salePrice = salePrice == 'null' ? parseFloat(price) : parseFloat(salePrice)
            price = parseFloat(price)
          }
        }

        let itemObj = {
          name: productData.name,
          id: productData.sku,
          quantity: cartItems[i].quantity,
          price: salePrice,
          item_category: gender,
          item_category2: sport,
          item_category3: category_1,
          item_category4: category_2,
          item_brand: 'GOREWEAR',
          variant: cartItems[i].variant.sku,
          item_regular_price: price,
          item_size: cartItems[i].variant.itemSize.label,
          item_rating: productReview.score ? String(productReview.score) : '0',
          item_review_count: productReview.total_count ? String(productReview.total_count) : '',
          item_gender: gender,
          item_color: cartItems[i].variant?.itemcolor?.label,
          item_sport: sport,
          item_upc: cartItems[i].variant?.upc,
          item_stock: cartItems[i].product?.availability,
        }

        if (coupons) {
          itemObj.coupon = coupons
        }

        finalItemList.push(itemObj)
      }
      return {
        cartAmount: copyCart.cart_amount,
        itemList: finalItemList,
      }
    }
  } catch (error) {
    console.log(error.message)
  }
}

export const getCurrentProductOptions = (currentProduct) => {
  const { optionLabels, allOptionsLabels } = getDefaultProductOptions(currentProduct.options)
  let defaultVariant
  let availableVariants = currentProduct.variants.filter(
    (variant) => variant.inventory_level > 0 && !variant.purchasing_disabled
  )

  defaultVariant = getDefaultVariant(availableVariants, optionLabels, allOptionsLabels)
  if (defaultVariant == undefined) {
    defaultVariant = currentProduct.variants[0]
  }
  let options = defaultVariant.option_values

  let data = {
    Color: '',
    Size: '',
  }

  options.map((opt) => {
    if (opt.option_display_name == 'Color') {
      data.Color = opt.label
    } else if (opt.option_display_name == 'Size') {
      data.Size = opt.label
    }
  })
  return data
}

export const getProductsPDPdata = async (products) => {
  try {
    const region = getCurrentRegion()

    if (products?.length > 0) {
      let productsPromises = products
        ?.map(async (product) => {
          try {
            let newProduct = await getProductBySku(product?.sku, true)

            if (!newProduct) {
              return null
            }

            if (newProduct?.variants) {
              const imagesArray = await getProductImagesEdges(newProduct.id)
              newProduct.imagesArray = imagesArray
              newProduct.images = flattenGraphqlArray(imagesArray)
              newProduct.variants = newProduct?.variants?.filter(
                (variant) => !variant.purchasing_disabled
              )
              let variants = newProduct.variants
              for (let i = 0; i < variants.length; i++) {
                delete newProduct.variants[i]['bin_picking_number']
                delete newProduct.variants[i]['calculated_weight']
                delete newProduct.variants[i]['cost_price']
                delete newProduct.variants[i]['depth']
                delete newProduct.variants[i]['fixed_cost_shipping_price']
                delete newProduct.variants[i]['gtin']
                delete newProduct.variants[i]['height']
                delete newProduct.variants[i]['inventory_warning_level']
                delete newProduct.variants[i]['is_free_shipping']
                delete newProduct.variants[i]['map_price']
                delete newProduct.variants[i]['mpn']
                delete newProduct.variants[i]['purchasing_disabled_message']
                delete newProduct.variants[i]['retail_price']
                delete newProduct.variants[i]['weight']
                delete newProduct.variants[i]['width']
              }
            }

            newProduct.sizes = getLocalizedOptions(newProduct, region?.localeDomain, 'sizes')

            newProduct.colors = getLocalizedOptions(newProduct, region?.localePrefix, 'colors')

            const designedFor = getLocalizedMetaField(
              newProduct?.metafields,
              region?.localePrefix,
              'designed_for',
              'key'
            )
            newProduct.designedFor = designedFor ? formatMetafieldValue(designedFor?.value) : ''

            const whyWeMadeThis = getLocalizedMetaField(
              newProduct?.metafields,
              region?.localePrefix,
              'why_we_made_this',
              'key'
            )

            newProduct.whyWeMadeThis = whyWeMadeThis
              ? formatMetafieldValue(whyWeMadeThis?.value)
              : ''

            const benefitStatement = getLocalizedMetaField(
              newProduct?.metafields,
              region?.localePrefix,
              'product_benefit_statement',
              'key'
            )
            newProduct.benefitStatement = benefitStatement
              ? formatMetafieldValue(benefitStatement.value)
              : ''

            const currentChoices = getCurrentProductOptions(newProduct)

            const variantId = getVariantId(newProduct?.variants, currentChoices)

            const selectedVariant = newProduct?.variants.find(
              (variant) => variant?.id === variantId
            )

            newProduct.defaultChoices = {
              Color: selectedVariant?.option_values.find(
                (item) => item?.option_display_name == 'Color'
              ).label,
              Size: selectedVariant?.option_values.find(
                (item) => item?.option_display_name == 'Size'
              ).label,
            }
            newProduct.selectedVariant = selectedVariant?.sku

            const normalisedProduct = getNormalisedProduct(newProduct, region)

            normalisedProduct.modules = null
            normalisedProduct.reviews = null
            normalisedProduct.featureComponent = null

            return normalisedProduct
          } catch (error) {
            console.log('the error', error)
            return null
          }
        })
        ?.filter(Boolean)

      const newProducts = await Promise.all(productsPromises)

      return newProducts
    }

    return null
  } catch (error) {
    console.log('preparePDPdata error', error)
  }
}

// used in finding the number in product images number key
export const findNumberInString = (str) => {
  const regex = /\d+/

  const match = str.match(regex)

  return match ? parseInt(match[0], 10) : null
}

// used for sorting product images by number
export const sortGroupedImagesByNumber = (images) => {
  try {
    if (!images) return []

    const newImages = images
      ?.map((image) => {
        return {
          ...image,
          description: {
            ...images?.description,
            number: findNumberInString(image?.description?.number),
          },
        }
      })
      .sort((a, b) => a?.description?.number - b?.description?.number)

    return newImages
  } catch (error) {
    console.log('sortGroupedImagesByNumber', error)
    return images
  }
}

export const getDiscountIdsFromCartItems = (cartItems) => {
  try {
    if (cartItems?.length < 1) {
      return null
    }

    let uniqueDiscountIds = []

    cartItems?.forEach((item) => {
      if (item?.discounts?.length > 0) {
        item?.discounts?.forEach((discount) => {
          if (!uniqueDiscountIds.includes(discount?.id) && discount?.id !== 'coupon') {
            uniqueDiscountIds.push(discount?.id)
          }
        })
      }
    })

    return uniqueDiscountIds?.length > 0 ? uniqueDiscountIds : null
  } catch (error) {
    console.log('the cart items', cartItems)
    return null
  }
}

export const prepareCollectionDataParallel = async (data, category = null) => {
  try {
    let region = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
    let returnCollectionData = []
    const maxRetries = 3

    if (!data || data.length === 0) {
      return returnCollectionData
    }

    const processProduct = async (item) => {
      if (!item?.sku && !item?.attributes?.sku) {
        return null
      }

      let bcProductData = await getProductBySku(item?.sku || item?.attributes?.sku)

      if (
        !bcProductData ||
        bcProductData.images.length === 0 ||
        bcProductData.variants.length === 0 ||
        bcProductData.options.length === 0
      ) {
        return null
      }

      // filter purchasable variants
      bcProductData.variants = bcProductData.variants.filter(
        (variant) => !variant.purchasing_disabled
      )

      // set variant prices from variant metafields
      bcProductData.variants.forEach((variant) => {
        let localePrice = variant.price != null ? parseFloat(variant.price) : 0
        let localeSalePrice = variant.sale_price != null ? parseFloat(variant.sale_price) : 0

        if (region.domain !== 'en-us') {
          const priceMeta = variant.metafields
            .find((meta) => meta.key === `${region.isoCode.toLowerCase()}_price`)
            ?.value?.replace(/[{""}]/g, '')
          localePrice = priceMeta && priceMeta !== 'null' ? parseFloat(priceMeta) : 0

          const salePriceMeta = variant.metafields
            .find((meta) => meta.key === `${region.isoCode.toLowerCase()}_sale_price`)
            ?.value?.replace(/[{""}]/g, '')
          localeSalePrice =
            salePriceMeta && salePriceMeta !== 'null' ? parseFloat(salePriceMeta) : 0
        }

        variant.price = localePrice
        variant.sale_price = localeSalePrice
      })

      // filter colors that are in stock
      const variantColors = inStockColors(bcProductData?.variants)

      // get product color
      const colorSequence = bcProductData?.options.find(
        (opt) => opt?.display_name === 'Color'
      )?.option_values

      // set available colors based on in stock variants
      const availableColors = colorSequence.filter((obj) => variantColors.includes(obj.label))

      // sort the available colors
      availableColors.sort((a, b) => {
        if (a?.is_default && !b?.is_default) {
          return -1
        } else if (!a?.is_default && b?.is_default) {
          return 1
        } else {
          return a?.sort_order - b?.sort_order
        }
      })

      // sort variants bv sale price
      const variantsSorted = sortBySalePrice(bcProductData?.variants)

      // set color code, size and hover image data
      variantsSorted?.forEach((variant) => {
        const colorCode = variant?.sku?.split('-')[1]
        variant.colorCode = colorCode
        variant.color = variant.option_values.find(
          (opt) => opt.option_display_name === 'Color'
        ).label
        variant.size = variant.option_values.find((opt) => opt.option_display_name === 'Size').label
        const hoverImage = getHoverImage(bcProductData.images, variant.color) || null
        variant.hoverImage = hoverImage?.url_standard || null

        if (variant.metafields.length > 0) {
          variant.sizeTrans =
            variant.metafields
              .find((meta) => meta.key === `${getSizeLocaleKey()}_size`)
              ?.value?.replace(/[{""}]/g, '') || variant.size
        }
      })

      // get product sizes
      const availableSizes = bcProductData?.options.find(
        (opt) => opt?.display_name === 'Size'
      )?.option_values

      // sort sizes based on sort order
      availableSizes.sort((a, b) => a.sort_order - b.sort_order)
      const sortedSizes = availableSizes.map((size) => size.label)

      // group variants by color
      const groupedVariants = groupByColor(variantsSorted, sortedSizes)

      let finalColorSorting = []

      if (category && category?.sort_rules) {
        const sortRule = JSON.parse(category?.sort_rules)
        const firstSortRule = sortRule ? Object.keys(sortRule)?.[0] : null

        if (firstSortRule === 'colororder' && sortRule?.colororder?.split('|')?.length > 0) {
          const sortOrder = sortRule?.colororder.split('|')

          variantsSorted.sort((a, b) => {
            const indexA = sortOrder?.indexOf(a.colorCode)
            const indexB = sortOrder?.indexOf(b.colorCode)
            if (indexA === -1 && indexB === -1) {
              return 0
            } else if (indexA === -1) {
              return 1
            } else if (indexB === -1) {
              return -1
            }
            return indexA - indexB
          })
        }

        variantsSorted.forEach((variant) => {
          const color = variant?.option_values.find(
            (opt) => opt?.option_display_name === 'Color'
          ).label
          if (!finalColorSorting?.includes(color) && variant?.inventory_level > 0) {
            finalColorSorting.push(color)
          }
        })
      } else {
        finalColorSorting = availableColors.map((item) => item.label)
      }

      if (finalColorSorting.length === 0) {
        const onlyPurchasableColors = Object.keys(groupedVariants)
        finalColorSorting = colorSequence
          .map((item) => (onlyPurchasableColors?.includes(item.label) ? item?.label : null))
          .filter((item) => item)
      }

      let defaultVariant = {
        color: null,
        size: null,
        vId: null,
        price: null,
        salePrice: null,
        imageUrl: null,
        hoverImage: null,
      }

      if (finalColorSorting.length > 0) {
        defaultVariant.color = finalColorSorting?.[0]
        const defaultSize = bcProductData?.options
          .find((opt) => opt?.display_name === 'Size')
          ?.option_values.find((opt) => opt?.is_default)

        if (defaultSize) {
          defaultVariant.size = defaultSize?.label
          const selectedVariant = variantsSorted?.find(
            (variant) =>
              variant?.color?.toLowerCase() === defaultVariant?.color?.toLowerCase() &&
              variant?.size?.toLowerCase() === defaultVariant?.size?.toLowerCase()
          )

          if (selectedVariant && selectedVariant?.inventory_level > 0) {
            defaultVariant.vId = selectedVariant?.id
            defaultVariant.price = selectedVariant?.price
            defaultVariant.salePrice = selectedVariant?.sale_price
            defaultVariant.imageUrl = selectedVariant?.image_url
            defaultVariant.hoverImage = selectedVariant?.hoverImage
          }
        } else {
          const availableVariant = groupedVariants?.[defaultVariant.color]?.find(
            (variant) => variant?.inventory_level > 0
          )

          if (availableVariant) {
            defaultVariant.size = availableVariant?.size
            defaultVariant.vId = availableVariant?.id
            defaultVariant.price = availableVariant?.price
            defaultVariant.salePrice = availableVariant?.sale_price
            defaultVariant.imageUrl = availableVariant?.image_url
            defaultVariant.hoverImage = availableVariant?.hoverImage
          } else {
            const firstVariant = groupedVariants?.[defaultVariant.color]?.[0]
            defaultVariant.size = firstVariant?.size
            defaultVariant.vId = firstVariant?.id
            defaultVariant.price = firstVariant?.price
            defaultVariant.salePrice = firstVariant?.sale_price
            defaultVariant.imageUrl = firstVariant?.image_url
            defaultVariant.hoverImage = firstVariant?.hoverImage
          }
        }
      }

      const meta_benefit_statement = getMetaFieldValueByRegion(
        bcProductData.metafields,
        region.domain,
        'product_benefit_statement',
        region
      )
      const product_details = getMetaFieldValueByRegion(
        bcProductData.metafields,
        region.domain,
        'product_details',
        region
      )
      const product_name = bcProductData?.title

      const categoryName = getCategoryName(item)

      const gender_custom_field = getCustomFieldValueByRegion(
        bcProductData?.custom_fields,
        'gender'
      )
      const sport_activity_custom_field = getCustomFieldValueByRegion(
        bcProductData?.custom_fields,
        'sport_activity'
      )
      const is_coming_soon_custom_field = getCustomFieldValueByRegion(
        bcProductData?.custom_fields,
        'is_coming_soon'
      )

      const is_new_custom_field = getCustomFieldValueByRegion(
        bcProductData?.custom_fields,
        'is_new'
      )
      const fabric_tech_custom_field = getCustomFieldValueByRegion(
        bcProductData?.custom_fields,
        'fabric_tech'
      )
      const new_colours_custom_field = getCustomFieldValueByRegion(
        bcProductData?.custom_fields,
        'new_colours'
      )
      const thumbnailImg = bcProductData.images.find((img) => img.is_thumbnail)?.url_thumbnail

      const tempProductData = {
        id: bcProductData.id,
        description: meta_benefit_statement,
        product_details: product_details,
        is_coming_soon: is_coming_soon_custom_field,
        is_new: is_new_custom_field,
        new_colours: new_colours_custom_field,
        fabric_tech: fabric_tech_custom_field,
        categoriesId: bcProductData.categories || [],
        name: product_name,
        category: categoryName,
        weight: bcProductData.weight || 0,
        selectedVariant: defaultVariant,
        sku: bcProductData.sku,
        intellisuggestData: item?.intellisuggestData || null,
        intellisuggestSignature: item?.intellisuggestSignature || null,
        slug: bcProductData.defaultSlug,
        options: bcProductData?.options || null,
        custom_fields: bcProductData.custom_fields,
        reviewsCount: getProductReviewCount(bcProductData.id),
        gender: gender_custom_field,
        sport_activity: sport_activity_custom_field,
        imageRecUrl: thumbnailImg || null,
        groupedVariants: groupedVariants,
        availableColors: finalColorSorting,
        enProductName: bcProductData.name,
      }

      return tempProductData
    }

    const processProductWithRetry = async (item, retries = 0) => {
      try {
        return await processProduct(item)
      } catch (error) {
        console.log('processProductWithRetry', error)
        if (retries < maxRetries) {
          return processProductWithRetry(item, retries + 1)
        } else {
          return null
        }
      }
    }

    const processedItems = await Promise.all(data.map((item) => processProductWithRetry(item)))
    returnCollectionData.push(...processedItems.filter((item) => item !== null))

    return returnCollectionData
  } catch (error) {
    console.log('prepareParallelCollectionData error', error)
    return []
  }
}

export const getMetaFieldValueByRegion = (metafields, domain, key, region) => {
  if (domain === 'en-us') {
    const metaValue = metafields.find((meta) => meta.key === `en_${key}` || meta.key === key)?.value
    return metaValue && isJsonString(metaValue) ? JSON.parse(metaValue)?.en : ''
  } else {
    const metaValue = metafields.find(
      (meta) => meta.key === `${region?.localePrefix}_${key}`
    )?.value
    return metaValue ? metaValue.replace(/[{""}]/g, '') : ''
  }
}

export const getCustomFieldValueByRegion = (customFields, key) => {
  return customFields.find((item) => item.name === `en_${key}` || item.name === key)?.value || ''
}

export const getCategoryName = (item) => {
  let catName = ''
  const categoriesAttribute = item?.categories || item?.attributes?.categories
  if (categoriesAttribute?.includes('Multi Sport')) {
    catName = 'Multi Sport'
  } else {
    for (const cat of categoriesAttribute) {
      const val = cat?.includes('&amp;') ? cat?.replace('&amp;', '&') : cat
      const arr = ['Cycling', 'Running', 'Ski']
      if (arr?.includes(val)) {
        if (catName === '') {
          catName = val
          break
        } else {
          catName = `${catName} ${val}`
          break
        }
      }
    }
    if (categoriesAttribute?.includes('Men') && categoriesAttribute?.includes('Women')) {
      catName = catName
    } else if (categoriesAttribute?.includes('Men')) {
      catName = `${catName} . Men`
    } else if (categoriesAttribute?.includes('Women')) {
      catName = `${catName} . Women`
    }
  }
  return catName
}
